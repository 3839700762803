import React, { Component } from 'react';
import { Rechecks } from '../Rechecks';
import ChevronLeft from 'baseui/icon/chevron-left';
import ChevronRight from 'baseui/icon/chevron-right';
import { Button, KIND } from 'baseui/button';
import { Block } from 'baseui/block';
import dateFns, { subDays, addDays } from 'date-fns';
import { Head } from '../Head';
import { ActionMenu, ActionMenuOption } from '../ScheduleActionMenu';
import { Redirect } from 'react-router-dom';
import { isoDate, dateForISODate } from '../../../shared/dateHelpers';

function currentDate(): Date {
    return new Date();
}

function formatDate(date: Date): string {
    return dateFns.format(date, 'MMMM DD, YYYY');
}

interface Props {
    date?: string;
}

interface State {
    date: Date;
    isMenuExpanded: boolean;
    actionOption?: ActionMenuOption;
}

export class Schedule extends Component<Props, State> {
    state: State = {
        date: this.props.date ? dateForISODate(this.props.date)! : currentDate(),
        isMenuExpanded: false,
    };

    render() {
        if (this.state.actionOption) {
            return <Redirect to={`/${this.state.actionOption}`} />;
        } else {
            const { date } = this.state;
            return (
                <>
                    <Block
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Button kind={KIND.tertiary} onClick={this.onPrevDay}>
                            <ChevronLeft />
                        </Button>
                        <Block font="font500" style={{ fontWeight: 'normal' }}>
                            {formatDate(date)}
                        </Block>
                        <Button kind={KIND.tertiary} onClick={this.onNextDay}>
                            <ChevronRight />
                        </Button>
                        <Button kind={KIND.tertiary} onClick={this.onWeek}>
                            Week
                        </Button>
                    </Block>

                    <Head text="Rechecks" />
                    <Rechecks date={date} />
                    <ActionMenu
                        expanded={this.state.isMenuExpanded}
                        onToggle={this.onToggle}
                        onSelect={this.onSelect}
                    />
                </>
            );
        }
    }

    onPrevDay = () => {
        const date = subDays(this.state.date, 1);
        history.pushState({}, '', `/schedule/days/${isoDate(date)}`);
        this.setState({ date });
    };

    onNextDay = () => {
        const date = addDays(this.state.date, 1);
        history.pushState({}, '', `/schedule/days/${isoDate(date)}`);
        this.setState({ date });
    };

    onToggle = () => {
        this.setState({ isMenuExpanded: !this.state.isMenuExpanded });
    };

    onSelect = (actionOption: ActionMenuOption) => {
        this.setState({ isMenuExpanded: false, actionOption });
    };

    onWeek = () => {
        alert('TODO');
    };
}
