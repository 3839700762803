import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import { TextInput } from './TextInput';
import { ArchiveAnimalMutation, MUTATION } from './graphql/ArchiveAnimalMutation';
import { HealthRecord } from '../types/HealthRecord';
import { KIND } from 'baseui/button';

interface Props {
    healthRecord: HealthRecord;
    onClose: () => void;
    isOpen: boolean;
}

export function ArchiveAnimalModal({ healthRecord, isOpen, onClose }: Props) {
    const id = parseInt(healthRecord.id as any);
    const [reason, setReason] = useState('');
    const variables = { id, fields: { reason } };

    const onUpdate = async (mutation: () => void) => {
        await mutation();
        onClose();
    };

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>Archive</ModalHeader>
            <ModalBody>
                <Block display="flex" flexDirection="column">
                    <TextInput value={reason} onChange={setReason} label="Reason" />
                </Block>{' '}
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose} kind={KIND.minimal}>
                    Cancel
                </ModalButton>
                <ArchiveAnimalMutation mutation={MUTATION} variables={variables}>
                    {mutation => (
                        <ModalButton onClick={() => onUpdate(mutation)}>Archive</ModalButton>
                    )}
                </ArchiveAnimalMutation>
            </ModalFooter>
        </Modal>
    );
}
