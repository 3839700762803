import gql from 'graphql-tag';
import { HealthRecordInput, ReportSickAnimalArgs } from '../../../shared/ReportSickAnimal';
import { useMutation } from 'react-apollo-hooks';
import * as fragments from './fragments';

const MUTATION = gql`
    mutation ReportSickAnimal($fields: HealthRecordInput!) {
        reportSickAnimal(fields: $fields) {
            ...HealthRecordSummary
        }
    }
    ${fragments.HealthRecordSummary}
`;

interface Data {
    healthRecord: HealthRecordInput;
}

type Variables = ReportSickAnimalArgs;

export function useReportSickAnimalMutation(variables: Variables) {
    return useMutation<Data, Variables>(MUTATION, { variables });
}
