import React from 'react';
import { Block } from 'baseui/block';
import { StatefulSelect } from 'baseui/select';
import { FormControl } from 'baseui/form-control';
import { RoomsQuery } from './graphql/RoomsQuery';

interface Option {
    value: string;
    label: string;
}

interface Props {
    facility: string;
    value: string;
    onChange: (value: string) => void;
}

export function RoomSelect(props: Props) {
    const onChange = ({ value }: { value: Option[] }) => {
        props.onChange(value.length > 0 ? value[0].value : '');
    };

    return (
        <Block display="flex" flexDirection="column" marginRight="scale200" style={{ flexGrow: 1 }}>
            <FormControl label="Room">
                <RoomsQuery facility={props.facility}>
                    {rooms => {
                        const options = rooms.map(({ name }) => ({ value: name, label: name }));
                        const initial = options.find(({ value }) => value === props.value);
                        const value: Option[] = initial ? [initial] : [];

                        return (
                            <StatefulSelect
                                options={options}
                                creatable
                                labelKey="label"
                                valueKey="value"
                                onChange={onChange}
                                initialState={{ value }}
                                clearable={false}
                                escapeClearsValue={false}
                                maxDropdownHeight="200px"
                                overrides={{
                                    SelectArrow: { style: { display: 'none' } },
                                }}
                                placeholder={<span />}
                            />
                        );
                    }}
                </RoomsQuery>
            </FormControl>
        </Block>
    );
}
