import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
    component?: any;
    exact?: true;
    path: string;
    isAuthenticated: boolean;
    render?: (props: any) => JSX.Element;
}

export const PrivateRoute = ({ component: Component, render, isAuthenticated, ...rest }: Props) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated ? (
                Component ? (
                    <Component {...props} />
                ) : (
                    render!(props)
                )
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
    />
);
