import React, { Component } from 'react';
import { TextInput } from '../TextInput';
import { Button } from 'baseui/button';
import { PageTitle } from '../PageTitle';
import { Head } from '../Head';
import { withRouter, RouteComponentProps } from 'react-router';
import { HealthRecordQuery } from '../graphql/HealthRecordQuery';
import { LabStaffForAcucQuery } from '../graphql/LabStaffForAcucQuery';
import { useSendSickAnimalNotificationMutation } from '../graphql/SendSickAnimalNotificationMutation';
import { EmailBody } from '../EmailBody';
import { HealthRecord } from '../../types/HealthRecord';

function healthRecordLocation(healthRecord: HealthRecord) {
    return `${healthRecord.facility} / Rm ${healthRecord.room} / ${healthRecord.cageLoc} / ${
        healthRecord.numOfNum
    }`;
}

interface State {
    message: string;
}

type Props = RouteComponentProps<any> & { id: number };

export class SendSickAnimalNotification extends Component<Props, State> {
    state: State = {
        message: '',
    };

    render() {
        const sendNotification = useSendSickAnimalNotificationMutation({
            id: this.props.id,
            message: this.state.message,
        });

        return (
            <HealthRecordQuery id={this.props.id}>
                {healthRecord => (
                    <LabStaffForAcucQuery
                        lab={healthRecord.lab ? healthRecord.lab.name : ''}
                        acuc={healthRecord.acuc}
                    >
                        {labStaff => (
                            <>
                                <PageTitle title="Send Notification" />

                                <Head text="To" />
                                {labStaff.map(labStaff => (
                                    <div>
                                        {labStaff.name} {labStaff.email}
                                    </div>
                                ))}

                                <Head text="CC" />
                                <p>Vet staff; Husbandry staff</p>

                                <Head text="Subject" />
                                <p>
                                    Report new sick {healthRecord.species}
                                    at {healthRecordLocation(healthRecord)}
                                </p>

                                <TextInput
                                    label="Body"
                                    multiline={true}
                                    value={this.state.message}
                                    onChange={message => this.setState({ message })}
                                />

                                <EmailBody healthRecord={healthRecord} />

                                <Button onClick={() => this.onSubmit(sendNotification)}>
                                    Submit
                                </Button>
                            </>
                        )}
                    </LabStaffForAcucQuery>
                )}
            </HealthRecordQuery>
        );
    }

    onSubmit = async (mutation: () => void) => {
        const result: any = await mutation();
        this.props.history.push(`/health-records/${this.props.id}`);
    };
}

export default withRouter(SendSickAnimalNotification);
