import React from 'react';
import { Block } from 'baseui/block';
import { Acuc } from '../types/HealthRecord';
import { StatefulSelect } from 'baseui/select';
import { AcucsQuery } from './graphql/AcucsQuery';
import { FormControl } from 'baseui/form-control';

interface Option {
    value: string;
    label: string;
}

interface Props {
    lab: string;
    value: string;
    onChange: (value: string) => void;
}

export function AcucSelect(props: Props) {
    const buildOptions = (acucs: Acuc[]): Option[] => {
        return acucs.map(acuc => ({ value: acuc.name, label: acuc.name }));
    };

    const onChange = ({ value }: { value: Option[] }) => {
        props.onChange(value.length > 0 ? value[0].value : '');
    };

    return (
        <Block display="flex" flexDirection="column" marginRight="scale200" style={{ flexGrow: 1 }}>
            <FormControl label="ACUC#">
                <AcucsQuery lab={props.lab}>
                    {acucs => {
                        const options = buildOptions(acucs);
                        const initial = options.find(({ value }) => value === props.value);
                        const value: Option[] = initial ? [initial] : [];
                        return (
                            <StatefulSelect
                                options={options}
                                creatable
                                labelKey="label"
                                valueKey="value"
                                onChange={onChange}
                                initialState={{ value }}
                                clearable={false}
                                escapeClearsValue={false}
                                maxDropdownHeight="200px"
                                overrides={{
                                    SelectArrow: { style: { display: 'none' } },
                                }}
                                placeholder={<span />}
                            />
                        );
                    }}
                </AcucsQuery>
            </FormControl>
        </Block>
    );
}
