import React from 'react';
import { Link } from 'react-router-dom';
import { FacilitiesQuery } from './graphql/FacilitiesQuery';
import { Facility } from '../types/HealthRecord';
import { Block } from 'baseui/block';
import ChevronRight from 'baseui/icon/chevron-right';

interface FacilityProps {
    facility: Facility;
}

const FacilityLink = ({ facility }: FacilityProps) => (
    <Link to={`/browse/facilities/${facility.name}`} className="health-record-list-item">
        <Block
            font="font400"
            display="flex"
            justifyContent="space-between"
            style={{ borderTop: '1px solid #ccc', padding: '0.25em 0' }}
            alignItems="center"
        >
            <Block font="font400">{facility.name || 'N/A'}</Block>
            <ChevronRight size="2em" />
        </Block>
    </Link>
);

interface FacilitiesLinkListProps {
    facilities: Facility[];
}

const FacilitiesLinkList = ({ facilities }: FacilitiesLinkListProps) => (
    <>
        {facilities.map((facility, key) => (
            <FacilityLink key={key} facility={facility} />
        ))}
    </>
);

export const BrowseFacilities = () => (
    <Block style={{ borderBottom: '1px solid #ccc' }}>
        <FacilitiesQuery>
            {facilities => <FacilitiesLinkList facilities={facilities} />}
        </FacilitiesQuery>
    </Block>
);
