import React from 'react';
import gql from 'graphql-tag';
import { Acuc } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';
import * as fragments from './fragments';

const QUERY = gql`
    query acucsForLab($lab: String!) {
        acucsForLab(lab: $lab) {
            ...Acuc
        }
    }
    ${fragments.Acuc}
`;

interface Data {
    acucsForLab: Acuc[];
}

interface Variables {
    lab: string;
}

type Props = Variables & {
    children: (summaries: Acuc[]) => JSX.Element;
};

export function AcucsQuery({ lab, children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY, { variables: { lab } });
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.acucsForLab);
}
