import React from 'react';
import { HealthRecordSummary } from '../types/HealthRecord';

function FieldValue({ value }: { value: string }) {
    if (value) {
        return <span style={{ fontWeight: 'bold' }}> {value} </span>;
    } else {
        return <span style={{ fontWeight: 'normal' }}> N/A </span>;
    }
}

interface Props {
    healthRecord: HealthRecordSummary;
    isSummary?: boolean;
}

function animalIdentifier({ healthRecord, isSummary }: Props) {
    return isSummary
        ? healthRecord.numOfNum || healthRecord.animalId || healthRecord.strain
        : healthRecord.numOfNum;
}

export const AnimalLocation = ({ healthRecord, isSummary }: Props) => (
    <>
        <FieldValue value={healthRecord.facility} />
        <span style={{ fontWeight: 'normal' }}>&bull;</span> Rm{' '}
        <FieldValue value={healthRecord.room} />
        <span style={{ fontWeight: 'normal' }}>&bull;</span>{' '}
        <FieldValue value={healthRecord.cageLoc} />
        <span style={{ fontWeight: 'normal' }}>&bull;</span>
        <FieldValue value={animalIdentifier({ healthRecord, isSummary })} />
    </>
);
