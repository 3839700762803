import React from 'react';
import gql from 'graphql-tag';
import { TreatmentFrequency } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';

const QUERY = gql`
    {
        treatmentFrequencies {
            name
        }
    }
`;

interface Data {
    treatmentFrequencies: TreatmentFrequency[];
}

interface Variables {}

export function useTreatmentFrequenciesQuery() {
    return useQuery<Data, Variables>(QUERY);
}

type Props = Variables & {
    children: (rooms: TreatmentFrequency[]) => JSX.Element;
};

export function TreatmentFrequenciesQuery({ children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY);
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.treatmentFrequencies);
}
