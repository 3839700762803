import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { HealthRecord } from '../types/HealthRecord';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import { TextInput } from './TextInput';
import { KIND } from 'baseui/button';
import { UpdateLabMutation, MUTATION } from './graphql/UpdateLabMutation';
import { LabSelect } from './LabSelect';
import { AcucSelect } from './AcucSelect';
import { labName } from '../helpers/HealthRecord';

interface Props {
    healthRecord: HealthRecord;
    onClose: () => void;
    isOpen: boolean;
}

export function EditLabDetails({ healthRecord, isOpen, onClose }: Props) {
    const id = parseInt(healthRecord.id as any);
    const [lab, setLab] = useState(labName(healthRecord));
    const [acuc, setAcuc] = useState(healthRecord.acuc);
    const [cageId, setCageId] = useState(healthRecord.cageId);
    const variables = { id, fields: { lab, acuc, cageId } };

    const onUpdate = async (mutation: () => void) => {
        await mutation();
        onClose();
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <ModalHeader>Edit Lab/PI</ModalHeader>
            <ModalBody>
                <LabSelect value={lab} onChange={setLab} />
                <Block display="flex" flexDirection="row">
                    <AcucSelect lab={lab} value={acuc} onChange={setAcuc} />
                    <TextInput value={cageId} onChange={setCageId} label="Cage ID" />
                </Block>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose} kind={KIND.minimal}>
                    Cancel
                </ModalButton>
                <UpdateLabMutation mutation={MUTATION} variables={variables}>
                    {mutation => <ModalButton onClick={() => onUpdate(mutation)}>Okay</ModalButton>}
                </UpdateLabMutation>
            </ModalFooter>
        </Modal>
    );
}
