import React from 'react';
import { useRechecksForDateQuery } from './graphql/RechecksForDateQuery';
import { HealthRecordList } from './HealthRecordList';
import { isoDate } from '../../shared/dateHelpers';
import { groupBy } from 'lodash';
import { SubHead } from './Head';
import { Block } from 'baseui/block';
import { Loading, LoadingError } from './Loading';

export function Rechecks({ date }: { date: Date }) {
    const { data, error, loading } = useRechecksForDateQuery(isoDate(date));
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;

    const healthRecords = data!.rechecksForDate;

    if (healthRecords.length === 0) {
        return (
            <Block style={{ paddingTop: '0.5em', fontWeight: 'normal' }} font="font500">
                None
            </Block>
        );
    } else {
        const grouped = groupBy(healthRecords, healthRecord => healthRecord.facility);
        return (
            <>
                {Object.entries(grouped).map(([facility, healthRecords], index) => (
                    <React.Fragment key={index}>
                        <SubHead text={facility} />
                        <HealthRecordList healthRecords={healthRecords} />
                    </React.Fragment>
                ))}
            </>
        );
    }
}
