import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';

class _ScrollToTop extends Component<RouteComponentProps<any, StaticContext, any>> {
    componentDidUpdate() {
        let { action } = this.props.history;
        if (action === 'PUSH') {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export const ScrollToTop = withRouter(_ScrollToTop);
