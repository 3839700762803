import gql from 'graphql-tag';
import React from 'react';
import { HealthRecord } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';
import * as fragments from './fragments';

const QUERY = gql`
    query healthRecord($id: Int!) {
        healthRecord(id: $id) {
            ...FullHealthRecord
        }
    }
    ${fragments.FullHealthRecord}
`;

type ResultType = HealthRecord;

interface Data {
    healthRecord: ResultType;
}

interface Variables {
    id: number;
}

type Props = Variables & {
    children: (result: ResultType) => JSX.Element;
};

export function HealthRecordQuery(props: Props) {
    const { children, ...variables } = props;
    const { data, error, loading } = useQuery<Data, Variables>(QUERY, { variables });
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.healthRecord);
}
