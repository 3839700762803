import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { HealthRecordProps, HealthRecord } from '../../types/HealthRecord';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { PageTitle } from '../PageTitle';
import { Checkbox } from 'baseui/checkbox';
import { TextInput } from '../TextInput';
import { FormControl } from 'baseui/form-control';
import { Datepicker } from 'baseui/datepicker';
import { dateForISODate, isoDateAddDays, isoDate } from '../../../shared/dateHelpers';
import { TreatmentFrequencySelect } from '../TreatmentFrequencySelect';
import { compose } from 'react-apollo';
import { withHealthRecord } from '../withHealthRecord';
import { EmailCompose } from '../EmailCompose';
import { ResolutionSelect } from '../ResolutionSelect';
import { NextStepChoice } from '../NextStepChoice';
import { useRecheckAnimalMutation } from '../graphql/RecheckAnimalMutation';
import { NextStepKind } from '../../../shared/RecheckAnimal';
import { TreatmentSummary } from '../TreatmentSummary';

function nextRecheckDate(healthRecord: HealthRecord) {
    return isoDateAddDays(healthRecord.recheckDate, 7);
}

function emailSubject(healthRecord: HealthRecord) {
    const location = [
        healthRecord.facility,
        `Rm ${healthRecord.room}`,
        healthRecord.cageLoc,
        healthRecord.numOfNum,
    ].join(' / ');
    return `Update for ${healthRecord.species} at ${location}`;
}

function emailMessage(healthRecord: HealthRecord) {
    const labName = healthRecord.lab ? healthRecord.lab.name : '';
    const salutation = `Dr. ${labName} and lab staff`;
    return `Hi ${salutation},\n\nPlease see the updated proposed treatment plan included here.`;
}

type Props = RouteComponentProps<any> & HealthRecordProps;

export function RecheckAnimal({ history, healthRecord }: Props) {
    const [requiresUpdate, setRequiresUpdate] = useState(false);
    const [subjective, setSubjective] = useState('');
    const [assessment, setAssessment] = useState(healthRecord.assessment);
    const [plan, setPlan] = useState(healthRecord.plan);
    const [treatmentFrequency, setTreatmentFrequency] = useState(healthRecord.treatmentFrequency);
    const [recheckDate, setRecheckDate] = useState(nextRecheckDate(healthRecord));
    const [requiresApproval, setRequiresApproval] = useState(false);
    const [notify, setNotify] = useState(false);
    const [{ subject, message }, setEmailData] = useState({
        subject: emailSubject(healthRecord),
        message: emailMessage(healthRecord),
    });
    const [nextStep, setNextStep] = useState<NextStepKind>('recheck');
    const [resolveKind, setResolveKind] = useState('euthanize');

    const id = parseInt(healthRecord.id as any);

    const recheck = useRecheckAnimalMutation({
        id,
        fields: {
            requiresUpdate,
            subjective,
            assessment,
            plan,
            treatmentFrequency,
            recheckDate,
            requiresApproval,
            notify,
            subject,
            message,
            nextStep,
            resolveKind,
        },
    });

    const onSubmit = async (mutation: () => void) => {
        await mutation();
        history.push(`/health-records/${healthRecord.id}`);
    };

    const goBack = () => {
        history.push(`/health-records/${healthRecord.id}`);
    };

    const changeRecheckDate = ({ date }: { date: Date }) => {
        setRecheckDate(isoDate(date));
    };

    return (
        <>
            <PageTitle title="Recheck Animal" onBack={() => goBack()} />
            <TreatmentSummary healthRecord={healthRecord} />
            <TextInput
                label="New Subjective/Objective"
                value={subjective}
                onChange={setSubjective}
                multiline={true}
            />
            <Block style={{ marginTop: '1em' }}>
                <Checkbox
                    checked={requiresUpdate}
                    onChange={() => setRequiresUpdate(!requiresUpdate)}
                >
                    Update treatment?
                </Checkbox>
            </Block>

            {requiresUpdate ? (
                <div style={{ marginTop: '1em' }}>
                    <TextInput
                        label="Assessment"
                        value={assessment}
                        onChange={setAssessment}
                        multiline={true}
                    />
                    <TextInput label="Plan" value={plan} onChange={setPlan} multiline={true} />
                    <TreatmentFrequencySelect
                        value={treatmentFrequency}
                        onChange={value => setTreatmentFrequency(value)}
                    />
                    <NextStepChoice value={nextStep} onChange={action => setNextStep(action)} />
                    {nextStep === 'recheck' ? (
                        <FormControl label="Recheck On">
                            <Datepicker
                                value={dateForISODate(recheckDate)}
                                onChange={changeRecheckDate}
                                autoFocusCalendar={true}
                            />
                        </FormControl>
                    ) : (
                        <ResolutionSelect onChange={resolveKind => setResolveKind(resolveKind)} />
                    )}

                    <Checkbox
                        checked={requiresApproval}
                        onChange={() => setRequiresApproval(!requiresApproval)}
                    >
                        Requires vet approval?
                    </Checkbox>
                    <Block style={{ marginTop: '1em' }}>
                        <Checkbox checked={notify} onChange={() => setNotify(!notify)}>
                            Notify via email?
                        </Checkbox>

                        {notify ? (
                            <EmailCompose
                                healthRecord={healthRecord}
                                subject={subject}
                                message={message}
                                onChange={(subject, message) => setEmailData({ subject, message })}
                            />
                        ) : (
                            ''
                        )}
                    </Block>
                </div>
            ) : (
                ''
            )}

            {!requiresUpdate ? (
                <Block style={{ marginTop: '1.5em' }}>
                    <FormControl label="Recheck On">
                        <Datepicker
                            value={dateForISODate(recheckDate)}
                            onChange={changeRecheckDate}
                            autoFocusCalendar={true}
                        />
                    </FormControl>
                </Block>
            ) : (
                ''
            )}

            <Block style={{ marginTop: '2em' }}>
                <Button onClick={() => onSubmit(recheck)}>Submit</Button>
            </Block>
        </>
    );
}

export default compose(
    withRouter,
    withHealthRecord,
)(RecheckAnimal);
