import React from 'react';
import { Block } from 'baseui/block';
import { Spinner } from 'baseui/spinner';
import { ApolloError } from 'apollo-client';

export const Loading = () => (
    <Block style={{ margin: '0.5em' }}>
        <Spinner size="1.5em" />
    </Block>
);

interface Props {
    error: ApolloError;
}

export const LoadingError = ({ error }: Props) => <div>Error! {error.message}</div>;
