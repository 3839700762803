import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { HealthRecord } from '../types/HealthRecord';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import { TextInput } from './TextInput';
import { KIND } from 'baseui/button';
import { UpdateAnimalMutation } from './graphql/UpdateAnimalMutation';
import { SpeciesSelect } from './Species';
import { FormControl } from 'baseui/form-control';

interface Props {
    healthRecord: HealthRecord;
    onClose: () => void;
    isOpen: boolean;
}

export function EditAnimalDetails({ healthRecord, isOpen, onClose }: Props) {
    const id = parseInt(healthRecord.id as any);
    const [species, setSpecies] = useState(healthRecord.species);
    const [strain, setStrain] = useState(healthRecord.strain);
    const [animalId, setAnimalId] = useState(healthRecord.animalId);

    const onUpdate = async (mutation: () => void) => {
        await mutation();
        onClose();
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <ModalHeader>Edit Animal</ModalHeader>
            <ModalBody>
                <FormControl label="Species">
                    <SpeciesSelect value={species} onChange={setSpecies} />
                </FormControl>
                <Block display="flex" flexDirection="row">
                    <TextInput value={strain} onChange={setStrain} label="Strain" />
                    <TextInput value={animalId} onChange={setAnimalId} label="Animal ID" />
                </Block>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose} kind={KIND.minimal}>
                    Cancel
                </ModalButton>
                <UpdateAnimalMutation variables={{ id, fields: { species, strain, animalId } }}>
                    {mutation => <ModalButton onClick={() => onUpdate(mutation)}>Okay</ModalButton>}
                </UpdateAnimalMutation>
            </ModalFooter>
        </Modal>
    );
}
