import React from 'react';
import { Block } from 'baseui/block';
import { AnimalLocation } from './AnimalLocation';
import { HealthRecord } from '../types/HealthRecord';

interface AnimalLocationHeaderProps {
    healthRecord: HealthRecord;
    openModal?: () => void;
}

export const AnimalLocationHeader = ({ healthRecord, openModal }: AnimalLocationHeaderProps) => (
    <Block onClick={openModal || (() => void 0)} font="font500" style={{ fontWeight: 'normal' }}>
        <AnimalLocation healthRecord={healthRecord} />
    </Block>
);
