import React from 'react';
import mouseIcon from '../icons/mouse-icon.svg';
import pigeonIcon from '../icons/pigeon-icon.svg';
import unknownIcon from '../icons/unknown-icon.svg';

const speciesIconUrls: { [species: string]: string } = {
    mouse: mouseIcon,
    pigeon: pigeonIcon,
    unknown: unknownIcon,
};

interface Props {
    species: string;
    className?: string;
}

export const SpeciesIcon = ({ species, className = 'species-icon' }: Props) => (
    <img src={speciesIconUrls[species] || unknownIcon} className={className} />
);
