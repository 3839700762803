import React from 'react';
import gql from 'graphql-tag';
import { LabStaff } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';
import * as fragments from './fragments';

const QUERY = gql`
    query labStaffForAcuc($lab: String!, $acuc: String!) {
        labStaffForAcuc(lab: $lab, acuc: $acuc) {
            ...LabStaff
        }
    }
    ${fragments.LabStaff}
`;

interface Data {
    labStaffForAcuc: LabStaff[];
}

interface Variables {
    lab: string;
    acuc: string;
}

interface Props {
    lab: string;
    acuc: string;
    children: (summaries: LabStaff[]) => JSX.Element;
}

export function LabStaffForAcucQuery({ lab, acuc, children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY, { variables: { lab, acuc } });
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.labStaffForAcuc);
}
