import gql from 'graphql-tag';

export const FullHealthRecord = gql`
    fragment FullHealthRecord on HealthRecord {
        id
        org {
            name
        }

        facility
        room
        cageLoc
        numOfNum

        lab {
            name
        }
        acuc
        cageId
        cageCardPhotoUrl

        species
        strain
        animalId

        subjective
        assessment
        plan
        treatmentFrequency
        recheckDate
        reportedDate
        vetStaffNotes

        requiresVetApproval
        requiresLabApproval
        resolution

        archiveReason
        archivedBy {
            id
            name
        }
        archivedAt

        treatmentReviews {
            id
            reviewerRole
            approve
            notes
            reviewer {
                id
                name
            }
            reviewedAt
        }
        treatmentUpdates {
            id
            requiresUpdate
            requiresApproval
            shouldSendNotification
            subjective
            assessment
            plan
            treatmentFrequency
            recheckDate
            author {
                id
                name
            }
            createdAt
        }
        treatmentRechecks {
            id
            requiresUpdate
            requiresApproval
            shouldSendNotification
            subjective
            assessment
            plan
            treatmentFrequency
            nextStep
            recheckDate
            resolution
            author {
                id
                name
            }
            createdAt
        }
        archiveAnimalActions {
            id
            reason
            author {
                id
                name
            }
            createdAt
        }

        updates {
            kind
            updateAt
            treatmentReview {
                id
                reviewerRole
                approve
                notes
                reviewer {
                    id
                    name
                }
                reviewedAt
            }
            treatmentUpdate {
                id
                requiresUpdate
                requiresApproval
                shouldSendNotification
                subjective
                assessment
                plan
                treatmentFrequency
                recheckDate
                author {
                    id
                    name
                }
                createdAt
            }
            treatmentRecheck {
                id
                requiresUpdate
                requiresApproval
                shouldSendNotification
                subjective
                assessment
                plan
                treatmentFrequency
                nextStep
                recheckDate
                resolution
                author {
                    id
                    name
                }
                createdAt
            }
            archiveAnimalAction {
                id
                reason
                author {
                    id
                    name
                }
                createdAt
            }
        }
    }
`;
