import React from 'react';
import { Block } from 'baseui/block';
import { LabStaff } from '../types/HealthRecord';
import { Checkbox } from 'baseui/checkbox';

function shouldReceiveNotifications(person: LabStaff, acuc: string) {
    const labStaffAcuc = person.labStaffAcucs.find(item => item.acuc === acuc);
    return labStaffAcuc ? labStaffAcuc.receiveNotifications : false;
}

interface LabStaffPersonProps {
    person: LabStaff;
    acuc: string;
}

const LabStaffPerson = ({ person, acuc }: LabStaffPersonProps) => (
    <Block
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        style={{ borderBottom: '1px solid #ccc' }}
    >
        <Block display="flex" flexDirection="column">
            <Block font="font450">{person.name}</Block>
            <Block font="font300">
                {person.title} - {person.email}
            </Block>
        </Block>
        <Block>
            <Checkbox checked={shouldReceiveNotifications(person, acuc)} onChange={() => {}} />
        </Block>
    </Block>
);

interface Props {
    acuc: string;
    people: LabStaff[];
}

export const LabStaffList = ({ acuc, people }: Props) => (
    <>
        <Block
            width="100%"
            display="flex"
            justifyContent="space-between"
            style={{ borderBottom: '1px solid #ccc' }}
        >
            <Block font="font300">Person</Block>
            <Block font="font300">Receive notifications?</Block>
        </Block>
        {people.length === 0 ? <Block font="font400">None</Block> : ''}
        {people.map((person, key) => (
            <LabStaffPerson person={person} acuc={acuc} key={key} />
        ))}
    </>
);
