import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';

const MUTATION = gql`
    mutation SendSickAnimalNotification($id: Int!, $message: String!) {
        sendSickAnimalNotification(id: $id, message: $message)
    }
`;

interface Data {}

interface Variables {
    id: number;
    message: string;
}

export function useSendSickAnimalNotificationMutation(variables: Variables) {
    return useMutation<Data, Variables>(MUTATION, { variables });
}
