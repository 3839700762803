import React, { Component } from 'react';
import '../styles/App.css';
import { Browse } from './pages/Browse';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { Schedule } from './pages/Schedule';
import ReportSickAnimal from './pages/ReportSickAnimal';
import { BrowseFacility } from './pages/BrowseFacility';
import { BrowseRoom } from './pages/BrowseRoom';
import HealthRecordView from './pages/HealthRecordView';
import HealthRecordReviewView from './pages/HealthRecordReviewView';
import UpdateTreatment from './pages/UpdateTreatment';
import Footer from './Footer';
import { Search } from './pages/Search';
import { Account } from './pages/Account';
import { BrowseLab } from './pages/BrowseLab';
import { BrowseAcuc } from './pages/BrowseAcuc';
import Login from './pages/Login';
import { PrivateRoute } from './PrivateRoute';
import SendSickAnimalNotification from './pages/SendSickAnimalNotification';
import { UnregisterCallback } from 'history';
import ApolloClient from 'apollo-client';
import { withApollo, compose } from 'react-apollo';
import RecheckAnimal from './pages/RecheckAnimal';

const Body = ({ children }: { children?: React.ReactNode }) => (
    <div
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '90%',
            width: '100%',
            overflow: 'scroll',
        }}
    >
        <div style={{ margin: '1em' }}>{children}</div>
    </div>
);

type Props = RouteComponentProps<any> & { client: ApolloClient<any> };

interface State {
    isAuthenticated: boolean;
}

class App extends Component<Props, State> {
    state: State = {
        isAuthenticated: !!localStorage.getItem('token'),
    };
    unlisten?: UnregisterCallback;

    componentWillMount() {
        this.unlisten = this.props.history.listen(_ => {
            const logout = this.state.isAuthenticated && !localStorage.getItem('token');
            if (logout) {
                this.setState({ isAuthenticated: false });
                this.props.client.resetStore();
            }
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
            this.unlisten = undefined;
        }
    }

    render() {
        return (
            <div>
                <Body>
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            render={_props => <Login onAuthenticate={this.authenticate} />}
                        />
                        <PrivateRoute
                            exact
                            path="/"
                            component={Schedule}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/schedule/days/:date"
                            render={props => <Schedule date={props.match.params.date} />}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/browse"
                            component={Browse}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/browse/facilities/:facility"
                            render={props => (
                                <BrowseFacility facility={props.match.params.facility} />
                            )}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/browse/facilities/:facility/rooms/:room"
                            render={props => (
                                <BrowseRoom
                                    facility={props.match.params.facility}
                                    room={props.match.params.room}
                                />
                            )}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/browse/labs/:lab"
                            render={props => <BrowseLab lab={props.match.params.lab} />}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/browse/labs/:lab/acucs/:acuc"
                            render={props => (
                                <BrowseAcuc
                                    lab={props.match.params.lab}
                                    acuc={props.match.params.acuc}
                                />
                            )}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/report-sick-animal"
                            component={ReportSickAnimal}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/report-sick-animal/:id/notify"
                            render={props => (
                                <SendSickAnimalNotification id={parseInt(props.match.params.id)} />
                            )}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/health-records/:id"
                            render={props => (
                                <HealthRecordView id={parseInt(props.match.params.id)} />
                            )}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/health-records/:id/review/:reviewerRole"
                            render={props => (
                                <HealthRecordReviewView
                                    id={parseInt(props.match.params.id)}
                                    reviewerRole={props.match.params.reviewerRole}
                                />
                            )}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/health-records/:id/update-treatment"
                            render={props => (
                                <UpdateTreatment id={parseInt(props.match.params.id)} />
                            )}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/health-records/:id/recheck-animal"
                            render={props => <RecheckAnimal id={parseInt(props.match.params.id)} />}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/search"
                            render={_props => <Search />}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                        <PrivateRoute
                            exact
                            path="/account"
                            render={_props => <Account />}
                            isAuthenticated={this.state.isAuthenticated}
                        />
                    </Switch>
                </Body>
                {this.state.isAuthenticated ? <Footer /> : ''}
            </div>
        );
    }

    authenticate = () => {
        this.setState({ isAuthenticated: true });
    };
}

export default compose(withApollo, withRouter)(App);
