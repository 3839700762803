import gql from 'graphql-tag';
import { HealthRecordSummary } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import * as fragments from './fragments';

const QUERY = gql`
    query RechecksForDate($date: String!) {
        rechecksForDate(date: $date) {
            ...HealthRecordSummary
        }
    }
    ${fragments.HealthRecordSummary}
`;

interface Data {
    rechecksForDate: Array<HealthRecordSummary>;
}

interface Variables {
    date: string;
}

export function useRechecksForDateQuery(date: string) {
    return useQuery<Data, Variables>(QUERY, { variables: { date } });
}
