import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import { TextInput } from './TextInput';
import { UpdateLocationMutation, MUTATION } from './graphql/UpdateLocationMutation';
import { HealthRecord } from '../types/HealthRecord';
import { KIND } from 'baseui/button';
import { FacilitySelect } from './FacilitySelect';
import { RoomSelect } from './RoomSelect';

interface Props {
    healthRecord: HealthRecord;
    onClose: () => void;
    isOpen: boolean;
}

export function EditLocation({ healthRecord, isOpen, onClose }: Props) {
    const id = parseInt(healthRecord.id as any);
    const [facility, setFacility] = useState(healthRecord.facility);
    const [room, setRoom] = useState(healthRecord.room);
    const [cageLoc, setCageLoc] = useState(healthRecord.cageLoc);
    const [numOfNum, setNumOfNum] = useState(healthRecord.numOfNum);
    const variables = { id, fields: { facility, room, cageLoc, numOfNum } };

    const onUpdate = async (mutation: () => void) => {
        await mutation();
        onClose();
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <ModalHeader>Edit Location</ModalHeader>
            <ModalBody>
                <Block display="flex" flexDirection="row">
                    <FacilitySelect value={facility} onChange={setFacility} />
                    <RoomSelect facility={facility} value={room} onChange={setRoom} />
                </Block>
                <Block display="flex" flexDirection="row">
                    <TextInput value={cageLoc} onChange={setCageLoc} label="Cage" />
                    <TextInput
                        value={numOfNum}
                        onChange={setNumOfNum}
                        label="# of #"
                        placeholder="e.g., 2 of 4"
                    />
                </Block>{' '}
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose} kind={KIND.minimal}>
                    Cancel
                </ModalButton>
                <UpdateLocationMutation mutation={MUTATION} variables={variables}>
                    {mutation => <ModalButton onClick={() => onUpdate(mutation)}>Okay</ModalButton>}
                </UpdateLocationMutation>
            </ModalFooter>
        </Modal>
    );
}
