import React from 'react';
import { Select } from 'baseui/select';

interface Species {
    id: string;
    name: string;
}

export const speciesList: Species[] = [
    { id: 'mouse', name: 'Mouse' },
    { id: 'rat', name: 'Rat' },
    { id: 'pigeon', name: 'Pigeon' },
    { id: 'hare', name: 'Hare' },
    { id: 'sheep', name: 'Sheep' },
    { id: 'cow', name: 'Cow' },
];

interface SelectEvent {
    value: Species[];
}

interface Props {
    value: string;
    onChange: (species: string) => void;
}

export const SpeciesSelect = ({ value, onChange }: Props) => (
    <Select
        options={speciesList}
        value={speciesList.find(species => species.id === value)}
        labelKey="name"
        valueKey="id"
        onChange={(e: SelectEvent) => (e.value.length > 0 ? onChange(e.value[0].id) : onChange(''))}
    />
);
