import React from 'react';
import { HealthRecordsForAcucQuery } from '../graphql/HealthRecordsForAcucQuery';
import { HealthRecordList } from '../HealthRecordList';
import { PageTitle } from '../PageTitle';
import { Head } from '../Head';
import { Breadcrumbs } from 'baseui/breadcrumbs';
import { Link } from 'react-router-dom';
import { LabStaffForAcucQuery } from '../graphql/LabStaffForAcucQuery';
import { ModalStateContainer } from '../ModalStateContainer';
import { AddLabStaff } from '../AddLabStaff';
import { Button } from 'baseui/button';
import { LabStaffList } from '../LabStaffList';

interface Props {
    lab: string;
    acuc: string;
}

export const BrowseAcuc = ({ lab, acuc }: Props) => (
    <>
        <PageTitle title="Browse" to="/browse" />
        <Breadcrumbs>
            <Link to={`/browse/labs/${lab}`} className="breadcrumbs-link">
                {lab}
            </Link>
            <span>ACUC #{acuc}</span>
        </Breadcrumbs>

        <Head text="Animals" />
        <HealthRecordsForAcucQuery lab={lab} acuc={acuc}>
            {summaries => <HealthRecordList healthRecords={summaries} />}
        </HealthRecordsForAcucQuery>

        <Head text="Lab Staff" />
        <LabStaffForAcucQuery lab={lab} acuc={acuc}>
            {labStaff => <LabStaffList acuc={acuc} people={labStaff} />}
        </LabStaffForAcucQuery>

        <ModalStateContainer>
            {({ open, close, isOpen }) => (
                <>
                    <Button onClick={open} style={{ marginTop: '0.5em' }}>
                        Add Person
                    </Button>
                    <AddLabStaff lab={lab} acuc={acuc} onClose={close} isOpen={isOpen} />
                </>
            )}
        </ModalStateContainer>
    </>
);
