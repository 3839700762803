import React, { useState } from 'react';
import { useReviewTreatmentMutation } from '../graphql/ReviewTreatmentMutation';
import { ReviewerRole } from '../../../shared/ReviewTreatment';
import { PageTitle } from '../PageTitle';
import { withRouter, RouteComponentProps } from 'react-router';
import { withHealthRecord } from '../withHealthRecord';
import { compose } from 'react-apollo';
import { TreatmentSummary } from '../TreatmentSummary';
import { TextInput } from '../TextInput';
import { Checkbox } from 'baseui/checkbox';
import { Button } from 'baseui/button';
import { HealthRecord } from '../../types/HealthRecord';

type Props = RouteComponentProps<any> & {
    healthRecord: HealthRecord;
    reviewerRole: ReviewerRole;
};

export function HealthRecordReviewView({ healthRecord, reviewerRole, history }: Props) {
    const [notes, setNotes] = useState('');
    const [approve, setApprove] = useState(true);

    const id = parseInt(healthRecord.id as any);
    const isLab = reviewerRole === 'lab';

    const submit = useReviewTreatmentMutation({ id, reviewerRole, approve, notes });

    const onSubmit = async (submit: () => void) => {
        await submit();
        history.push(`/health-records/${healthRecord.id}`);
    };

    return (
        <>
            <PageTitle title="Review Treatment" onBack={() => history.goBack()} />
            <TreatmentSummary healthRecord={healthRecord} showDetails={true} />

            <Checkbox checked={approve} onChange={() => setApprove(!approve)}>
                {isLab ? 'Lab approved proposal?' : 'Approve proposal?'}
            </Checkbox>
            <TextInput label="Notes" multiline={true} value={notes} onChange={setNotes} />
            <Button onClick={() => onSubmit(submit)}>Submit</Button>
        </>
    );
}

export default compose(
    withRouter,
    withHealthRecord,
)(HealthRecordReviewView);
