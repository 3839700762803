import { Mutation, MutationFn } from 'react-apollo';
import gql from 'graphql-tag';
import { AddLabStaffInput } from '../../../shared/HealthRecord';
import * as fragments from './fragments';

export const MUTATION = gql`
    mutation AddLabStaff($lab: String!, $acuc: String!, $fields: AddLabStaffInput!) {
        addLabStaff(lab: $lab, acuc: $acuc, fields: $fields) {
            ...LabStaff
        }
    }
    ${fragments.LabStaff}
`;

export interface Data {}

export interface Variables {
    lab: string;
    acuc: string;
    fields: AddLabStaffInput;
}

export class AddLabStaffMutation extends Mutation<Data, Variables> {}

export type AddLabStaffMutationFn = MutationFn<Data, Variables>;
