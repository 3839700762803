import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import { TextInput } from './TextInput';
import { KIND } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { AddLabStaffMutation, MUTATION } from './graphql/AddLabStaffMutation';

interface Props {
    lab: string;
    acuc: string;
    onClose: () => void;
    isOpen: boolean;
}

export function AddLabStaff({ lab, acuc, isOpen, onClose }: Props) {
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [receiveNotifications, setReceiveNotifications] = useState(true);

    const onUpdate = async (mutation: () => void) => {
        await mutation();
        onClose();
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <ModalHeader>Add Lab Staff</ModalHeader>
            <ModalBody>
                <TextInput value={name} onChange={setName} label="Name" />
                <TextInput value={title} onChange={setTitle} label="Title" />
                <TextInput value={email} onChange={setEmail} label="Email" />
                <Checkbox
                    checked={receiveNotifications}
                    onChange={() => setReceiveNotifications(!receiveNotifications)}
                >
                    Receive notifications?
                </Checkbox>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose} kind={KIND.minimal}>
                    Cancel
                </ModalButton>
                <AddLabStaffMutation
                    mutation={MUTATION}
                    variables={{ lab, acuc, fields: { name, title, email, receiveNotifications } }}
                >
                    {mutation => <ModalButton onClick={() => onUpdate(mutation)}>Okay</ModalButton>}
                </AddLabStaffMutation>
            </ModalFooter>
        </Modal>
    );
}
