import React from 'react';
import gql from 'graphql-tag';
import { HealthRecordSummary } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';
import * as fragments from './fragments';

const QUERY = gql`
    query healthRecordsForRoom($facility: String!, $room: String!) {
        healthRecordsForRoom(facility: $facility, room: $room) {
            ...HealthRecordSummary
        }
    }
    ${fragments.HealthRecordSummary}
`;

interface Data {
    healthRecordsForRoom: HealthRecordSummary[];
}

interface Variables {
    facility: string;
    room: string;
}

type Props = Variables & {
    children: (summaries: HealthRecordSummary[]) => JSX.Element;
};

export function HealthRecordsQuery(props: Props) {
    const { children, ...variables } = props;
    const { data, error, loading } = useQuery<Data, Variables>(QUERY, { variables });
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.healthRecordsForRoom);
}
