import { Mutation, MutationFn } from 'react-apollo';
import gql from 'graphql-tag';
import { UpdateLocationInput } from '../../../shared/HealthRecord';

export const MUTATION = gql`
    mutation UpdateLocation($id: Int!, $fields: UpdateLocationInput!) {
        updateLocation(id: $id, fields: $fields) {
            id
            facility
            room
            cageLoc
            numOfNum
        }
    }
`;

export interface Data {}

export interface Variables {
    id: number;
    fields: UpdateLocationInput;
}

export class UpdateLocationMutation extends Mutation<Data, Variables> {}

export type UpdateLocationMutationFn = MutationFn<Data, Variables>;
