import { Mutation, MutationFn } from 'react-apollo';
import gql from 'graphql-tag';
import { ArchiveAnimalInput } from '../../../shared/HealthRecord';
import * as fragments from './fragments';

export const MUTATION = gql`
    mutation ArchiveAnimal($id: Int!, $fields: ArchiveAnimalInput!) {
        archiveAnimal(id: $id, fields: $fields) {
            ...FullHealthRecord
        }
    }
    ${fragments.FullHealthRecord}
`;

export interface Data {}

export interface Variables {
    id: number;
    fields: ArchiveAnimalInput;
}

export class ArchiveAnimalMutation extends Mutation<Data, Variables> {}

export type ArchiveAnimalMutationFn = MutationFn<Data, Variables>;
