import React from 'react';
import gql from 'graphql-tag';
import { HealthRecordSummary } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';
import * as fragments from './fragments';

const QUERY = gql`
    query listHealthRecords($archived: Boolean!) {
        healthRecords(archived: $archived) {
            ...HealthRecordSummary
        }
    }
    ${fragments.HealthRecordSummary}
`;

interface Data {
    healthRecords: HealthRecordSummary[];
}

interface Variables {
    archived: boolean;
}

type Props = Variables & {
    archived: boolean;
    children: (summaries: HealthRecordSummary[]) => JSX.Element;
};

export function ListHealthRecordsQuery({ archived, children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY, { variables: { archived } });
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.healthRecords);
}
