import React from 'react';
import { Card } from 'baseui/card';
import { TextInput } from './TextInput';
import { EmailBody } from './EmailBody';
import { HealthRecord } from '../types/HealthRecord';

interface Props {
    healthRecord: HealthRecord;
    subject: string;
    message: string;
    onChange: (subject: string, message: string) => void;
}

export function EmailCompose({ healthRecord, subject, message, onChange }: Props) {
    const to = healthRecord.lab ? `${healthRecord.lab.name} lab staff` : '';
    const cc = 'Vet staff; Husbandry staff';
    return (
        <Card style={{ marginTop: '1em' }}>
            <p>
                To: <b>{to}</b>
            </p>

            <p>
                CC: <b>{cc}</b>
            </p>

            <TextInput
                label="Subject"
                value={subject}
                onChange={subject => onChange(subject, message)}
            />

            <TextInput
                label="Message"
                multiline={true}
                lines={10}
                value={message}
                onChange={message => onChange(subject, message)}
            />

            <EmailBody healthRecord={healthRecord} />
        </Card>
    );
}
