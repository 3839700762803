import React from 'react';
import { Block } from 'baseui/block';
import { Lab } from '../types/HealthRecord';
import { StatefulSelect } from 'baseui/select';
import { LabsQuery } from './graphql/LabsQuery';
import { FormControl } from 'baseui/form-control';

interface LabOption {
    value: string;
    label: string;
}

interface Props {
    value: string;
    onChange: (value: string) => void;
}

export function LabSelect(props: Props) {
    const labsForLabs = (labs: Lab[]): LabOption[] => {
        return labs.map(lab => ({ value: lab.name, label: lab.name }));
    };

    const onChange = ({ value }: { value: LabOption[] }) => {
        props.onChange(value.length > 0 ? value[0].value : '');
    };

    return (
        <Block display="flex" flexDirection="column" marginRight="scale200">
            <FormControl label="PI">
                <LabsQuery>
                    {labs_ => {
                        const labs = labsForLabs(labs_);
                        const initialLab = labs.find(lab => lab.value === props.value);
                        const value: LabOption[] = initialLab ? [initialLab] : [];
                        return (
                            <StatefulSelect
                                options={labs}
                                creatable
                                labelKey="label"
                                valueKey="value"
                                onChange={onChange}
                                initialState={{ value }}
                                clearable={false}
                                escapeClearsValue={false}
                                maxDropdownHeight="200px"
                                overrides={{
                                    SelectArrow: { style: { display: 'none' } },
                                }}
                                placeholder={<span />}
                            />
                        );
                    }}
                </LabsQuery>
            </FormControl>
        </Block>
    );
}
