import React from 'react';
import { Link } from 'react-router-dom';
import { HealthRecord } from '../types/HealthRecord';
import { ReviewerRole } from '../../shared/ReviewTreatment';
import { Button, KIND } from 'baseui/button';
import { ArrowRightCircle } from 'react-feather';

interface Props {
    kind: ReviewerRole;
    healthRecord: HealthRecord;
}

export const ApprovalLink = ({ kind, healthRecord }: Props) => (
    <Link
        to={`/health-records/${healthRecord.id}/review/${kind}`}
        style={{ textDecoration: 'none' }}
    >
        <Button
            kind={KIND.secondary}
            style={{ width: '100%', marginBottom: '1em' }}
            endEnhancer={() => <ArrowRightCircle size="2em" />}
        >
            Needs {kind} review
        </Button>
    </Link>
);
