import React, { useState } from 'react';
import request from 'superagent';
import Dropzone from 'react-dropzone';
import { Block } from 'baseui/block';

interface UploadedPhotoReponseBody {
    url: string;
}

interface UploadedPhotoResponse extends request.Response {
    body: UploadedPhotoReponseBody;
}

export interface UploadedPhoto {
    fileName: string;
    response: UploadedPhotoResponse;
}

interface Props {
    cloudName: string;
    uploadPreset: string;
    onPhotoChanged: (data?: UploadedPhoto) => void;
}

export function PhotoUploader({ cloudName, uploadPreset, onPhotoChanged }: Props) {
    const [uploadedPhoto, setUploadedPhoto] = useState();

    const onPhotoSelected = (files: File[] | null) => {
        const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
        const file = files![0];
        request
            .post(url)
            .field('upload_preset', uploadPreset)
            .field('file', file)
            .field('tags', 'hr_photo')
            .end((_error, response) => onPhotoUploaded({ fileName: file.name, response }));
    };

    const onPhotoUploaded = (uploadedPhoto: UploadedPhoto) => {
        setUploadedPhoto(uploadedPhoto);
        onPhotoChanged(uploadedPhoto);
    };

    const onDeleteUploadedPhoto = () => {
        setUploadedPhoto(undefined);
        onPhotoChanged();
    };

    if (uploadedPhoto) {
        return (
            <>
                <img src={uploadedPhoto!.response.body.url} />
                <div onClick={onDeleteUploadedPhoto}>Remove?</div>
            </>
        );
    } else {
        return (
            <Dropzone accept="image/*" onDrop={onPhotoSelected}>
                {({ getRootProps, getInputProps }) => (
                    <Block className="dropzone">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Block font="font400">
                                Drop a file here to upload
                                <br />
                                or
                                <br />
                                click to select files
                            </Block>
                        </div>
                    </Block>
                )}
            </Dropzone>
        );
    }
}
