import React from 'react';
import { ListHealthRecordsQuery } from './graphql/ListHealthRecordsQuery';
import { HealthRecordList } from './HealthRecordList';

interface Props {
    archived: boolean;
}

export const ListHealthRecords = ({ archived }: Props) => (
    <ListHealthRecordsQuery archived={archived}>
        {summaries => <HealthRecordList healthRecords={summaries} />}
    </ListHealthRecordsQuery>
);
