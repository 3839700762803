import React from 'react';
import { FormControl } from 'baseui/form-control';
import { StatefulSelect } from 'baseui/select';

export const resolveOptions = [
    { value: 'euthanize', label: 'Euthanize' },
    { value: 'use', label: 'Use by lab staff' },
    { value: 'recover', label: 'Recovered' },
];

export type ResolutionKind = 'euthanize' | 'use' | 'recover';

interface Props {
    onChange: (value: ResolutionKind) => void;
}

export const ResolutionSelect = ({ onChange }: Props) => (
    <FormControl label="Resolution">
        <StatefulSelect
            options={resolveOptions}
            searchable={false}
            labelKey="label"
            valueKey="value"
            onChange={(e: any) => onChange(e.value[0].value)}
            initialState={{ value: [resolveOptions[0]] }}
            clearable={false}
            escapeClearsValue={false}
            maxDropdownHeight="200px"
        />
    </FormControl>
);
