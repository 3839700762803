import React, { SyntheticEvent, useReducer } from 'react';
import { PhotoUploader, UploadedPhoto } from '../PhotoUploader';
import { HealthRecordInput } from '../../../shared/ReportSickAnimal';
import { useReportSickAnimalMutation } from '../graphql/ReportSickAnimalMutation';
import { TextInput } from '../TextInput';
import { Button } from 'baseui/button';
import { Textarea } from 'baseui/textarea';
import { FormControl } from 'baseui/form-control';
import { Datepicker } from 'baseui/datepicker';
import { Block } from 'baseui/block';
import { dateForISODate } from '../../../shared/dateHelpers';
import { SpeciesSelect } from '../Species';
import { PageTitle } from '../PageTitle';
import { Head } from '../Head';
import { withRouter, RouteComponentProps } from 'react-router';
import { LabSelect } from '../LabSelect';
import { AcucSelect } from '../AcucSelect';
import { FacilitySelect } from '../FacilitySelect';
import { RoomSelect } from '../RoomSelect';
import { TreatmentFrequencySelect } from '../TreatmentFrequencySelect';

type State = HealthRecordInput;

type Props = RouteComponentProps<any>;

function reducer(state: State, action: Partial<State>): State {
    return { ...state, ...action };
}

export function ReportSickAnimal(props: Props) {
    const [state, setState] = useReducer(reducer, {
        // Lab / PI
        lab: '',
        acuc: '',
        cageId: '',
        cageCardPhotoUrl: '',

        // Location
        facility: '',
        room: '',
        cageLoc: '',
        numOfNum: '',

        // Animal
        species: 'mouse',
        strain: '',
        animalId: '',

        // Issue
        subjective: '',
        assessment: '',
        plan: '',
        treatmentFrequency: '',
        reportedDate: '',
        recheckDate: '',
        vetStaffNotes: '',
    });

    const reportSickAnimal = useReportSickAnimalMutation({ fields: { ...state } });

    const onSubmit = async (mutation: () => void) => {
        const result: any = await mutation();
        props.history.push(`/report-sick-animal/${result.data.reportSickAnimal.id}/notify`);
    };

    const onPhotoChanged = (data?: UploadedPhoto) => {
        if (data) {
            setState({ cageCardPhotoUrl: data.response.body.url });
        } else {
            setState({ cageCardPhotoUrl: '' });
        }
    };

    const changeReportedDate = ({ date }: { date: Date }) => {
        setState({ reportedDate: date.toISOString().slice(0, 10) });
    };

    const changeRecheckDate = ({ date }: { date: Date }) => {
        setState({ recheckDate: date.toISOString().slice(0, 10) });
    };

    return (
        <>
            <div>
                <PageTitle title="Report a Sick Animal" />

                <Head text="Lab / PI" />

                <LabSelect value={state.lab} onChange={lab => setState({ lab })} />

                <Block display="flex" flexDirection="row">
                    <AcucSelect
                        lab={state.lab}
                        value={state.acuc}
                        onChange={acuc => setState({ acuc })}
                    />
                    {/* <TextInput
                            value={this.state.acuc}
                            onChange={e => this.setState({ acuc: e.target.value })}
                            label="ACUC#"
                        /> */}

                    <TextInput
                        value={state.cageId}
                        onChange={cageId => setState({ cageId })}
                        label="Cage ID"
                    />
                </Block>

                <FormControl label="Cage Card Photo">
                    <PhotoUploader
                        cloudName="btoe"
                        uploadPreset="hbzizynz"
                        onPhotoChanged={onPhotoChanged}
                    />
                </FormControl>

                <Head text="Location" />

                <Block display="flex" flexDirection="row">
                    <FacilitySelect
                        value={state.facility}
                        onChange={facility => setState({ facility })}
                    />

                    <RoomSelect
                        facility={state.facility}
                        value={state.room}
                        onChange={room => setState({ room })}
                    />
                </Block>
                <Block display="flex" flexDirection="row">
                    <TextInput
                        value={state.cageLoc}
                        onChange={cageLoc => setState({ cageLoc })}
                        label="Cage"
                    />

                    <TextInput
                        value={state.numOfNum}
                        onChange={numOfNum => setState({ numOfNum })}
                        label="# of #"
                        placeholder="e.g., 2 of 4"
                    />
                </Block>

                <Head text="Animal" />

                <FormControl label="Species">
                    <SpeciesSelect
                        value={state.species}
                        onChange={species => setState({ species })}
                    />
                </FormControl>

                <Block display="flex" flexDirection="row">
                    <TextInput
                        value={state.strain}
                        onChange={strain => setState({ strain })}
                        label="Strain"
                    />

                    <TextInput
                        value={state.animalId}
                        onChange={animalId => setState({ animalId })}
                        label="Animal ID"
                    />
                </Block>

                <Head text="Issue" />

                <FormControl label="Subjective/Objective">
                    <Textarea
                        value={state.subjective}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                            setState({ subjective: (e.target as any).value })
                        }
                    />
                </FormControl>

                <FormControl label="Assessment">
                    <Textarea
                        value={state.assessment}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                            setState({ assessment: (e.target as any).value })
                        }
                    />
                </FormControl>

                <FormControl label="Plan">
                    <Textarea
                        value={state.plan}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                            setState({ plan: (e.target as any).value })
                        }
                    />
                </FormControl>

                <TreatmentFrequencySelect
                    value={state.treatmentFrequency}
                    onChange={value => setState({ treatmentFrequency: value })}
                />

                <Block display="flex" flexDirection="row">
                    <Block display="flex" flexDirection="column" marginRight="scale200">
                        <FormControl label="Reported Date">
                            <Datepicker
                                value={dateForISODate(state.reportedDate)}
                                onChange={changeReportedDate}
                                autoFocusCalendar={true}
                            />
                        </FormControl>
                    </Block>
                    <Block display="flex" flexDirection="column" marginRight="scale200">
                        <FormControl label="Recheck Date">
                            <Datepicker
                                value={dateForISODate(state.recheckDate)}
                                onChange={changeRecheckDate}
                                autoFocusCalendar={true}
                            />
                        </FormControl>
                    </Block>
                </Block>

                <FormControl label="Notes (visible only to vet staff)">
                    <Textarea
                        value={state.vetStaffNotes}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                            setState({ vetStaffNotes: (e.target as any).value })
                        }
                    />
                </FormControl>
            </div>
            <Button onClick={() => onSubmit(reportSickAnimal)}>Submit</Button>
        </>
    );
}

export default withRouter(ReportSickAnimal);
