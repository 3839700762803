import React from 'react';
import { HealthRecordProps } from '../types/HealthRecord';
import { dateForISODate } from '../../shared/dateHelpers';
import * as dateFns from 'date-fns';

function formatDate(date: string): string {
    if (date) {
        return dateFns.format(dateForISODate(date)!, 'MMMM D');
    } else {
        return 'N/A';
    }
}

export function EmailBody({ healthRecord }: HealthRecordProps) {
    return (
        <div>
            <p>
                Location
                <br />
                <span style={{ fontSize: '1.2em' }}>
                    {healthRecord.facility} &bull; Rm {healthRecord.room} &bull;{' '}
                    {healthRecord.cageLoc} &bull; {healthRecord.numOfNum}
                </span>
            </p>

            <table style={{ width: '100%', margin: '1em 0', borderSpacing: 0 }}>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'top', padding: 0 }}>
                            Species
                            <br />
                            <span style={{ fontSize: '1.2em' }}>{healthRecord.species}</span>
                        </td>
                        <td style={{ verticalAlign: 'top', padding: 0 }}>
                            Strain
                            <br />
                            <span style={{ fontSize: '1.2em' }}>
                                {healthRecord.strain || 'N/A'}
                            </span>
                        </td>
                        <td style={{ verticalAlign: 'top', padding: 0 }}>
                            Animal ID
                            <br />
                            <span style={{ fontSize: '1.2em' }}>
                                {healthRecord.animalId || 'N/A'}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table style={{ width: '100%', margin: '1em 0', borderSpacing: 0 }}>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'top', padding: 0 }}>
                            Cage ID
                            <br />
                            <span style={{ fontSize: '1.2em' }}>
                                {healthRecord.cageId || 'N/A'}
                            </span>
                        </td>
                        <td style={{ verticalAlign: 'top', padding: 0 }}>
                            IACUC Protocol#
                            <br />
                            <span style={{ fontSize: '1.2em' }}>{healthRecord.acuc || 'N/A'}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>
                Cage Card Photo
                <br />
                <span style={{ fontSize: '1.2em' }}>
                    {healthRecord.cageCardPhotoUrl ? (
                        <img src={healthRecord.cageCardPhotoUrl} style={{ width: '100%' }} />
                    ) : (
                        'N/A'
                    )}
                </span>
            </p>

            <p>
                Observations
                <br /> <span style={{ fontSize: '1.2em' }}>{healthRecord.subjective || 'N/A'}</span>
            </p>

            <p>
                Assessment
                <br /> <span style={{ fontSize: '1.2em' }}>{healthRecord.assessment || 'N/A'}</span>
            </p>

            <p>
                Treatment Plan
                <br /> <span style={{ fontSize: '1.2em' }}>{healthRecord.plan || 'N/A'}</span>
            </p>

            <table style={{ width: '100%', margin: '1em 0', borderSpacing: 0 }}>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'top', padding: 0 }}>
                            Treatment Frequency
                            <br />{' '}
                            <span style={{ fontSize: '1.2em' }}>
                                {healthRecord.treatmentFrequency || 'N/A'}
                            </span>
                        </td>
                        <td style={{ verticalAlign: 'top', padding: 0 }}>
                            Recheck Date
                            <br />{' '}
                            <span style={{ fontSize: '1.2em' }}>
                                {formatDate(healthRecord.recheckDate) || 'N/A'}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
