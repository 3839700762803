import React from 'react';
import gql from 'graphql-tag';
import { User } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';
import * as fragments from './fragments';

const QUERY = gql`
    {
        me {
            ...User
        }
    }
    ${fragments.User}
`;

interface Data {
    me: User;
}

interface Variables {}

interface Props {
    children: (user: User) => JSX.Element;
}

export function MeQuery({ children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY);
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.me);
}
