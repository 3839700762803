import React from 'react';
import gql from 'graphql-tag';
import { Facility } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';

const QUERY = gql`
    {
        facilities {
            name
        }
    }
`;

interface Data {
    facilities: Facility[];
}

interface Variables {}

interface Props {
    children: (facilities: Facility[]) => JSX.Element;
}

export function FacilitiesQuery({ children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY);
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.facilities);
}
