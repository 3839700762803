import React from 'react';
import { Link } from 'react-router-dom';
import { LabsQuery } from './graphql/LabsQuery';
import { Lab } from '../types/HealthRecord';
import { Block } from 'baseui/block';
import ChevronRight from 'baseui/icon/chevron-right';

interface LabProps {
    lab: Lab;
}

const LabLink = ({ lab }: LabProps) => (
    <Link to={`/browse/labs/${lab.name}`} className="health-record-list-item">
        <Block
            font="font400"
            display="flex"
            justifyContent="space-between"
            style={{ borderTop: '1px solid #ccc', padding: '0.25em 0' }}
            alignItems="center"
        >
            <Block font="font400">{lab.name || 'N/A'}</Block>
            <ChevronRight size="2em" />
        </Block>
    </Link>
);

export const BrowseLabs = () => (
    <Block style={{ borderBottom: '1px solid #ccc' }}>
        <LabsQuery>
            {labs => (
                <>
                    {labs.map((lab, key) => (
                        <LabLink key={key} lab={lab} />
                    ))}
                </>
            )}
        </LabsQuery>
    </Block>
);
