import React, { Component } from 'react';
import { HealthRecordQuery } from './graphql/HealthRecordQuery';

interface Props {
    id: number;
}

type WithHealthRecord = (Comp: typeof Component) => (props: Props) => JSX.Element;

export const withHealthRecord: WithHealthRecord = Comp => props => (
    <HealthRecordQuery id={props.id}>
        {healthRecord => <Comp {...props} healthRecord={healthRecord} />}
    </HealthRecordQuery>
);
