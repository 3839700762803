import React from 'react';
import { Link } from 'react-router-dom';
import { SpeciesIcon } from './SpeciesIcon';
import { HealthRecordSummary } from '../types/HealthRecord';
import { Block } from 'baseui/block';
import { AnimalLocation } from './AnimalLocation';
import dateFns from 'date-fns';
import { dateForISODate } from '../../shared/dateHelpers';
import { needsRecheck } from '../helpers/HealthRecord';
import { CheckCircle } from 'react-feather';

function formatDate(dateStr: string): string | undefined {
    const date = dateForISODate(dateStr);
    if (date) {
        return dateFns.format(date, 'MMMM D');
    } else {
        return undefined;
    }
}

interface LinkProps {
    healthRecord: HealthRecordSummary;
}

const HealthRecordLink = ({ healthRecord }: LinkProps) => (
    <Block style={{ borderTop: '1px solid #ccc', padding: '0.25em 0' }}>
        <Link
            to={`/health-records/${healthRecord.id}`}
            className="health-record-list-item"
            style={{ width: '100%' }}
        >
            <Block
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="stretch"
                width="100%"
            >
                <Block
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    style={{ marginRight: '1em' }}
                    justifyContent="flex-start"
                >
                    <SpeciesIcon species={healthRecord.species} className="" />
                    <Block color="warning" style={{ marginTop: '0.5em' }}>
                        {needsRecheck(healthRecord) ? <CheckCircle /> : ''}
                    </Block>
                </Block>
                <Block display="flex" flexDirection="column" width="100%">
                    <Block font="font300">
                        <AnimalLocation healthRecord={healthRecord} isSummary={true} />
                        <div className="assessment">
                            Assessment: {healthRecord.assessment || 'N/A'}
                        </div>
                        <Block display="flex" flexDirection="row" justifyContent="space-between">
                            <Block style={{ marginRight: '1em' }}>
                                {' '}
                                Reported: {formatDate(healthRecord.reportedDate) || 'N/A'}
                            </Block>
                            <Block> PI: {healthRecord.lab ? healthRecord.lab.name : ''}</Block>
                        </Block>
                    </Block>
                </Block>
                <div className="arrow">›</div>
            </Block>
        </Link>
    </Block>
);

interface ListProps {
    healthRecords: HealthRecordSummary[];
}

export function HealthRecordList({ healthRecords }: ListProps) {
    if (healthRecords.length > 0) {
        return (
            <Block className="health-record-list" style={{ borderBottom: '1px solid #ccc' }}>
                {healthRecords.map((healthRecord, index) => (
                    <HealthRecordLink key={index} healthRecord={healthRecord} />
                ))}
            </Block>
        );
    } else {
        return <Block font="font400">None</Block>;
    }
}
