import React, { useState } from 'react';
import { HealthRecordProps } from '../types/HealthRecord';
import { PhotoUploader, UploadedPhoto } from './PhotoUploader';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { UpdateCageCardPhotoMutation } from './graphql/UpdateCageCardPhotoMutation';
import Delete from 'baseui/icon/delete';

export function CageCardView({ healthRecord: { id, cageCardPhotoUrl: url } }: HealthRecordProps) {
    const id_ = parseInt(id as any);
    const [isExpanded, setIsExpanded] = useState(false);
    const [cageCardPhotoUrl, setCageCardPhotoUrl] = useState(url);

    const onPhotoChanged = (mutation: (data: any) => void, data?: UploadedPhoto) => {
        const cageCardPhotoUrl = data ? data.response.body.url : '';
        mutation({ variables: { fields: { cageCardPhotoUrl } } });
        setCageCardPhotoUrl(cageCardPhotoUrl);
    };

    const onRemove = (mutation: (data: any) => void) => {
        if (!confirm('Are you sure?')) return;
        mutation({ variables: { fields: { cageCardPhotoUrl: '' } } });
        setCageCardPhotoUrl('');
    };

    const hasPhoto = cageCardPhotoUrl || url;

    if (isExpanded) {
        return (
            <>
                <Block font="font300" color="mono800" style={{ marginBottom: '0.25em' }}>
                    Cage Card Photo
                </Block>
                {hasPhoto ? <img src={cageCardPhotoUrl || url} width="100%" /> : ''}
                <UpdateCageCardPhotoMutation variables={{ id: id_, fields: { cageCardPhotoUrl } }}>
                    {mutation =>
                        hasPhoto ? (
                            <Button
                                kind={KIND.minimal}
                                startEnhancer={() => <Delete size={24} />}
                                onClick={() => onRemove(mutation)}
                            >
                                Remove Photo
                            </Button>
                        ) : (
                            <PhotoUploader
                                cloudName="btoe"
                                uploadPreset="hbzizynz"
                                onPhotoChanged={data => onPhotoChanged(mutation, data)}
                            />
                        )
                    }
                </UpdateCageCardPhotoMutation>
            </>
        );
    } else {
        return (
            <Button kind={KIND.tertiary} onClick={() => setIsExpanded(true)}>
                {hasPhoto ? 'Show' : 'Add'} cage card
            </Button>
        );
    }
}
