import React from 'react';
import { Link } from 'react-router-dom';
import { AcucsQuery } from '../graphql/AcucsQuery';
import { PageTitle } from '../PageTitle';
import { Head } from '../Head';
import { Block } from 'baseui/block';
import ChevronRight from 'baseui/icon/chevron-right';
import { Breadcrumbs } from 'baseui/breadcrumbs';

export interface Acuc {
    name: string;
}

const AcucLinks = ({ lab, acucs }: { lab: string; acucs: Acuc[] }) => (
    <>
        {acucs.map((acuc, index) => (
            <AcucLink key={index} lab={lab} acuc={acuc} />
        ))}
    </>
);

const AcucLink = ({ lab, acuc }: { lab: string; acuc: Acuc }) => (
    <Link to={`/browse/labs/${lab}/acucs/${acuc.name}`} className="health-record-list-item">
        <Block
            font="font400"
            display="flex"
            justifyContent="space-between"
            style={{ borderTop: '1px solid #ccc', padding: '0.25em 0' }}
            alignItems="center"
        >
            <Block>ACUC # {acuc.name}</Block>
            <ChevronRight size="2em" />
        </Block>
    </Link>
);

interface Props {
    lab: string;
}

export const BrowseLab = ({ lab }: Props) => (
    <>
        <PageTitle title="Browse" to="/browse" />
        <Breadcrumbs>
            <span>{lab}</span>
        </Breadcrumbs>

        <Head text="ACUC Protocols" />
        <Block style={{ borderBottom: '1px solid #ccc' }}>
            <AcucsQuery lab={lab}>
                {acucs => (
                    <>
                        {acucs.length === 0 ? <Block font="font400">None</Block> : ''}
                        <AcucLinks acucs={acucs} lab={lab} />
                    </>
                )}
            </AcucsQuery>
        </Block>
    </>
);
