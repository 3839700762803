import React from 'react';
import gql from 'graphql-tag';
import { Room } from '../pages/BrowseFacility';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';

const QUERY = gql`
    query roomsForFacility($facility: String!) {
        roomsForFacility(facility: $facility) {
            name
        }
    }
`;

interface Data {
    roomsForFacility: Room[];
}

interface Variables {
    facility: string;
}

type Props = Variables & {
    children: (rooms: Room[]) => JSX.Element;
};

export function RoomsQuery({ facility, children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY, { variables: { facility } });
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.roomsForFacility);
}
