import React, { useState } from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { TextInput } from '../TextInput';
import { Notification, KIND } from 'baseui/notification';
import { PageTitle } from '../PageTitle';
import { Button } from 'baseui/button';
import { Block } from 'baseui/block';
import { useLoginMutation, Data } from '../graphql/LoginMutation';

function InvalidLoginNotification({ show }: { show: boolean }) {
    if (show) {
        return <Notification kind={KIND.warning}>Invalid email or password</Notification>;
    } else {
        return <React.Fragment />;
    }
}

export type Props = RouteComponentProps<any> & {
    onAuthenticate: () => void;
};

function Login({ location, onAuthenticate }: Props) {
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('email') || '');
    const [password, setPassword] = useState('');
    const [invalidLogin, setInvalidLogin] = useState(false);

    const login = useLoginMutation({ email, password }, (_proxy, { data }: { data?: Data }) => {
        if (!data) return;
        localStorage.setItem('token', data.login.token);
        localStorage.setItem('email', data.login.user.email);
        localStorage.setItem('role', data.login.user.role);
        onAuthenticate();
        setRedirectToReferrer(true);
    });

    const loginOrError = async () => {
        try {
            await login();
        } catch (e) {
            setInvalidLogin(true);
        }
    };

    if (redirectToReferrer === true) {
        const from = location.state.from || { pathname: '/' };
        return <Redirect to={from} />;
    }

    return (
        <Block style={{ margin: 'auto', maxWidth: '800px' }}>
            <PageTitle title="Login" />
            <Block style={{ padding: '2em 0' }} />
            <InvalidLoginNotification show={invalidLogin} />
            <TextInput label="Email" value={email} onChange={setEmail} />
            <TextInput label="Password" value={password} onChange={setPassword} isPassword={true} />
            <Button onClick={loginOrError}>Log in</Button>
        </Block>
    );
}

export default withRouter(Login);
