import React, { Component } from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { HealthRecordQuery } from '../graphql/HealthRecordQuery';
import {
    HealthRecordProps,
    TreatmentReview,
    TreatmentUpdate,
    HealthRecordUpdate,
    ArchiveAnimalAction,
    TreatmentRecheck,
} from '../../types/HealthRecord';
import { ActionMenuOption, ActionMenu } from '../HealthRecordActionMenu';
import { ApprovalLink } from '../ApprovalLink';
import { AnimalHealthRecord } from '../AnimalHealthRecord';
import { Field } from '../Field';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { H5 } from 'baseui/typography';
import dateFns from 'date-fns';
import { dateForISODate } from '../../../shared/dateHelpers';
import { PageTitle } from '../PageTitle';
import { ArrowRightCircle } from 'react-feather';
import { Button, KIND } from 'baseui/button';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'react-feather';
import { needsRecheck } from '../../helpers/HealthRecord';
import { ArchiveAnimalModal } from '../ArchiveAnimalModal';
import { ModalStateContainer } from '../ModalStateContainer';

const Warning = ({ text }: { text: string }) => (
    <Block
        style={{ width: 'auto', marginBottom: '1em' }}
        font="font400"
        color="warning"
        alignItems="center"
        display="flex"
    >
        <AlertCircle size="1.25em" style={{ marginRight: '0.5em' }} />
        {text}
    </Block>
);

const RecheckLink = ({ healthRecord }: HealthRecordProps) => (
    <Link
        to={`/health-records/${healthRecord.id}/recheck-animal`}
        style={{ textDecoration: 'none' }}
    >
        <Button
            kind={KIND.secondary}
            style={{ width: '100%', marginBottom: '1em' }}
            endEnhancer={() => <ArrowRightCircle size="2em" />}
        >
            Needs recheck
        </Button>
    </Link>
);

function formatWhen(timestamp: number) {
    return dateFns.format(new Date(timestamp), 'MMMM D, YYYY h:mm A');
}

function formatDate(isoDate?: string) {
    const date = dateForISODate(isoDate);
    if (date) {
        return dateFns.format(date, 'MMMM D, YYYY');
    } else {
        return '';
    }
}

export function Treatment({ healthRecord }: HealthRecordProps) {
    if (healthRecord.reportedDate) {
        const isApproved = !(healthRecord.requiresVetApproval || healthRecord.requiresLabApproval);
        const reportedDate = formatDate(healthRecord.reportedDate);
        const canApproveAsVet = localStorage.getItem('role') === 'clinical-vet';

        return (
            <Block className="treatment" style={{ marginTop: '3em' }}>
                <Block font="font500" style={{ paddingBottom: '1em' }}>
                    {isApproved ? `Under treatment` : `Treatment proposed`}
                </Block>
                {needsRecheck(healthRecord) ? <RecheckLink healthRecord={healthRecord} /> : ''}
                {healthRecord.requiresVetApproval ? (
                    canApproveAsVet ? (
                        <ApprovalLink kind="vet" healthRecord={healthRecord} />
                    ) : (
                        <Warning text="Needs vet approval" />
                    )
                ) : (
                    ''
                )}
                {healthRecord.requiresLabApproval ? (
                    <ApprovalLink kind="lab" healthRecord={healthRecord} />
                ) : (
                    ''
                )}
                <div>
                    <Field label="Assessment" value={healthRecord.assessment} important={true} />
                    <Field label="Plan" value={healthRecord.plan} important={true} />
                    <Block display="flex" flexDirection="row">
                        <Field label="Tx Frequency" value={healthRecord.treatmentFrequency} />
                        <Field label="Recheck On" value={formatDate(healthRecord.recheckDate)} />
                    </Block>
                    <Field label="Notes" value={healthRecord.vetStaffNotes} important={true} />
                    <Field label="Reported On" value={reportedDate} />
                </div>
            </Block>
        );
    } else {
        return <div />;
    }
}

function reviewTitle(treatmentReview: TreatmentReview) {
    const reviewerRole = treatmentReview.reviewerRole === 'vet' ? 'Vet' : 'Lab';
    return treatmentReview.approve
        ? `Treatment Approved by ${reviewerRole}`
        : `Treatment Rejected by ${reviewerRole}`;
}

const TreatmentReviewUpdate = ({ treatmentReview }: { treatmentReview: TreatmentReview }) => (
    <Card title={reviewTitle(treatmentReview)} style={{ marginBottom: '1em' }}>
        <h4>{treatmentReview.notes}</h4>
        <div>
            {treatmentReview.reviewer.name} &bull; {formatWhen(treatmentReview.reviewedAt)}
        </div>
    </Card>
);

const TreatmentUpdateUpdate = ({ treatmentUpdate }: { treatmentUpdate: TreatmentUpdate }) => (
    <Card title="Treatment Updated" style={{ marginBottom: '1em' }}>
        <Field label="Subjective/Objective" value={treatmentUpdate.subjective} important={true} />
        <Field label="Assessment" value={treatmentUpdate.assessment} important={true} />
        <Field label="Plan" value={treatmentUpdate.plan} important={true} />
        <Block display="flex" flexDirection="row">
            <Field label="Recheck On" value={formatDate(treatmentUpdate.recheckDate)} />
            <Field label="Tx Frequency" value={treatmentUpdate.treatmentFrequency} />
        </Block>
        <Field label="Notes" value={treatmentUpdate.vetStaffNotes} important={true} />
        <div>
            {treatmentUpdate.author.name} &bull; {formatWhen(treatmentUpdate.createdAt)}
        </div>
    </Card>
);

const TreatmentRecheckUpdate = ({ treatmentRecheck }: { treatmentRecheck: TreatmentRecheck }) => (
    <Card title="Recheck" style={{ marginBottom: '1em' }}>
        <Field label="Subjective/Objective" value={treatmentRecheck.subjective} important={true} />
        <Field label="Assessment" value={treatmentRecheck.assessment} important={true} />
        <Field label="Plan" value={treatmentRecheck.plan} important={true} />
        <Block display="flex" flexDirection="row">
            <Field label="Recheck On" value={formatDate(treatmentRecheck.recheckDate)} />
            <Field label="Tx Frequency" value={treatmentRecheck.treatmentFrequency} />
        </Block>
        {/* <Field label="Notes" value={treatmentRecheck.vetStaffNotes} important={true} /> */}
        <div>
            {treatmentRecheck.author ? treatmentRecheck.author.name : 'N/A'} &bull;{' '}
            {formatWhen(treatmentRecheck.createdAt)}
        </div>
    </Card>
);

const ArchiveAnimalActionUpdate = ({
    archiveAnimalAction,
}: {
    archiveAnimalAction: ArchiveAnimalAction;
}) => (
    <Card title="Archived" style={{ marginBottom: '1em' }}>
        <Field label="Reason" value={archiveAnimalAction.reason} important={true} />
        <div>
            {archiveAnimalAction.author ? archiveAnimalAction.author.name : 'N/A'} &bull;{' '}
            {formatWhen(archiveAnimalAction.createdAt)}
        </div>
    </Card>
);

function Update({ update }: { update: HealthRecordUpdate }) {
    switch (update.kind) {
        case 'treatmentReview':
            return <TreatmentReviewUpdate treatmentReview={update.treatmentReview!} />;

        case 'treatmentUpdate':
            return <TreatmentUpdateUpdate treatmentUpdate={update.treatmentUpdate!} />;

        case 'treatmentRecheck':
            return <TreatmentRecheckUpdate treatmentRecheck={update.treatmentRecheck!} />;

        case 'archive':
            return <ArchiveAnimalActionUpdate archiveAnimalAction={update.archiveAnimalAction!} />;

        default:
            return null;
    }
}

export const Updates = ({ healthRecord }: HealthRecordProps) => (
    <>
        <H5 style={{ marginBottom: '0.5em' }}>Updates</H5>
        {healthRecord.updates.map((update, key) => (
            <Update key={key} update={update} />
        ))}
    </>
);

type Props = RouteComponentProps<any> & {
    id: number;
};

interface State {
    isMenuExpanded: boolean;
    actionOption?: ActionMenuOption;
}

export class HealthRecordView extends Component<Props, State> {
    state: State = {
        isMenuExpanded: false,
    };

    render() {
        if (this.state.actionOption && this.state.actionOption !== 'archive') {
            return <Redirect to={`/health-records/${this.props.id}/${this.state.actionOption}`} />;
        } else {
            return (
                <div>
                    <PageTitle title="Animal Record" onBack={() => this.props.history.goBack()} />
                    <HealthRecordQuery id={this.props.id}>
                        {healthRecord => (
                            <>
                                <AnimalHealthRecord healthRecord={healthRecord} />
                                <ModalStateContainer>
                                    {({ open, close, isOpen }) => (
                                        <>
                                            <ActionMenu
                                                expanded={this.state.isMenuExpanded}
                                                onToggle={this.onToggle}
                                                onSelect={this.onSelect(open)}
                                            />
                                            <ArchiveAnimalModal
                                                healthRecord={healthRecord}
                                                onClose={close}
                                                isOpen={isOpen}
                                            />
                                        </>
                                    )}
                                </ModalStateContainer>
                            </>
                        )}
                    </HealthRecordQuery>
                </div>
            );
        }
    }

    onToggle = () => {
        this.setState({ isMenuExpanded: !this.state.isMenuExpanded });
    };

    onSelect = (open: any) => (actionOption: ActionMenuOption) => {
        if (actionOption === 'archive') {
            open();
        }
        this.setState({ isMenuExpanded: false, actionOption });
    };
}

export default withRouter(HealthRecordView);
