import gql from 'graphql-tag';
import { RecheckAnimalInput } from '../../../shared/RecheckAnimal';
import { useMutation } from 'react-apollo-hooks';
import * as fragments from './fragments';

const MUTATION = gql`
    mutation RecheckAnimal($id: Int!, $fields: RecheckAnimalInput!) {
        recheckAnimal(id: $id, fields: $fields) {
            ...FullHealthRecord
        }
    }
    ${fragments.FullHealthRecord}
`;

interface Data {}

interface Variables {
    id: number;
    fields: RecheckAnimalInput;
}

export function useRecheckAnimalMutation(variables: Variables) {
    return useMutation<Data, Variables>(MUTATION, { variables });
}
