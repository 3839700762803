import React from 'react';
import gql from 'graphql-tag';
import { Lab } from '../../types/HealthRecord';
import { useQuery } from 'react-apollo-hooks';
import { Loading, LoadingError } from '../Loading';

const QUERY = gql`
    {
        labs {
            name
        }
    }
`;

interface Data {
    labs: Lab[];
}

interface Variables {}

type Props = Variables & {
    children: (summaries: Lab[]) => JSX.Element;
};

export function LabsQuery({ children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY);
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.labs);
}
