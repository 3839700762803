import React from 'react';
import { FormControl } from 'baseui/form-control';
import { RadioGroup, Radio } from 'baseui/radio';
import { NextStepKind } from '../../shared/RecheckAnimal';

interface Props {
    value: NextStepKind;
    onChange: (value: NextStepKind) => void;
}

export const NextStepChoice = ({ value, onChange }: Props) => (
    <FormControl label="Next Step">
        <RadioGroup
            align="horizontal"
            name="action"
            onChange={(e: any) => onChange(e.target.value)}
            value={value}
        >
            <Radio value="recheck" overrides={{ Label: { style: { marginRight: '2em' } } }}>
                Recheck
            </Radio>
            <Radio value="resolve" style={{ width: '50%' }}>
                Resolve
            </Radio>
        </RadioGroup>
    </FormControl>
);
