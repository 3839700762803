import React from 'react';
import { HealthRecord, HealthRecordProps } from '../types/HealthRecord';
import { SpeciesIcon } from './SpeciesIcon';
import { CageCardView } from './CageCardView';
import { Field } from './Field';
import { Block } from 'baseui/block';
import { EditAnimalDetails } from './EditAnimalDetails';
import { ModalStateContainer } from './ModalStateContainer';
import { EditLabDetails } from './EditLabDetails';
import { labName } from '../helpers/HealthRecord';

const AnimalDetails = ({ healthRecord }: HealthRecordProps) => (
    <ModalStateContainer>
        {({ open, close, isOpen }) => (
            <>
                <Block display="flex" flexDirection="row" onClick={open}>
                    <SpeciesIcon species={healthRecord.species!} />
                    <Field label="Strain" value={healthRecord.strain} />
                    <Field label="Animal ID" value={healthRecord.animalId} />
                </Block>
                <EditAnimalDetails healthRecord={healthRecord} onClose={close} isOpen={isOpen} />
            </>
        )}
    </ModalStateContainer>
);

const LabDetails = ({ healthRecord }: HealthRecordProps) => (
    <ModalStateContainer>
        {({ open, close, isOpen }) => (
            <>
                <Block display="flex" flexDirection="row" onClick={open}>
                    <Field label="PI" value={labName(healthRecord)} />
                    <Field label="ACUC#" value={healthRecord.acuc} />
                    <Field label="Cage ID" value={healthRecord.cageId} />
                </Block>
                <EditLabDetails healthRecord={healthRecord} onClose={close} isOpen={isOpen} />
            </>
        )}
    </ModalStateContainer>
);

export interface Props {
    healthRecord: HealthRecord;
    allowEdits: boolean;
}

export const AnimalHealthRecordDetail = ({ healthRecord, allowEdits }: Props) => (
    <Block style={{ marginTop: '1em' }}>
        <AnimalDetails healthRecord={healthRecord} />
        <LabDetails healthRecord={healthRecord} />
        {allowEdits ? (
            <Block>
                <CageCardView healthRecord={healthRecord} />
            </Block>
        ) : (
            ''
        )}
    </Block>
);
