import gql from 'graphql-tag';

export const HealthRecordSummary = gql`
    fragment HealthRecordSummary on HealthRecord {
        id
        org {
            name
        }

        facility
        room
        cageLoc
        numOfNum

        lab {
            name
        }
        acuc
        cageId

        species
        strain
        animalId

        subjective
        assessment
        plan
        treatmentFrequency
        recheckDate
        reportedDate
        vetStaffNotes

        archivedAt
    }
`;
