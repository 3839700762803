import gql from 'graphql-tag';
import { UpdateCageCardPhotoInput } from '../../../shared/HealthRecord';
import { useMutation } from 'react-apollo-hooks';

const MUTATION = gql`
    mutation UpdateCageCardPhoto($id: Int!, $fields: UpdateCageCardPhotoInput!) {
        updateCageCardPhoto(id: $id, fields: $fields) {
            id
            cageCardPhotoUrl
        }
    }
`;

interface Data {}

interface Variables {
    id: number;
    fields: UpdateCageCardPhotoInput;
}

interface Props {
    variables: Variables;
    children: (act: any) => JSX.Element;
}

export function UpdateCageCardPhotoMutation({ variables, children }: Props) {
    const updateTreatment = useMutation<Data, Variables>(MUTATION, { variables });
    return children(updateTreatment);
}
