import { HealthRecord, HealthRecordSummary } from '../types/HealthRecord';
import { dateForISODate } from '../../shared/dateHelpers';

export function needsRecheck(healthRecord: HealthRecord | HealthRecordSummary) {
    return healthRecord.recheckDate
        ? dateForISODate(healthRecord.recheckDate)!.getTime() < Date.now()
        : false;
}

export function labName(healthRecord: HealthRecord | HealthRecordSummary) {
    return healthRecord.lab ? healthRecord.lab.name : '';
}
