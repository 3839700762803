import React from 'react';
import { Button, SHAPE } from 'baseui/button';
import Plus from 'baseui/icon/plus';
import Delete from 'baseui/icon/delete';
import { StatefulMenu } from 'baseui/menu';

export type ActionMenuOption = 'update-treatment' | 'archive';

const ActionMenuButton = ({ toggle, expanded }: { toggle: () => void; expanded: boolean }) => (
    <Button onClick={toggle} shape={SHAPE.square} className="action-menu-icon">
        {expanded ? <Delete size="1.5em" /> : <Plus size="1.5em" />}
    </Button>
);

interface ActionMenuItem {
    id: ActionMenuOption;
    label: string;
}

const items: ActionMenuItem[] = [
    { id: 'update-treatment', label: 'Update Tx' },
    { id: 'archive', label: 'Archive' },
];

const ActionMenuItems = ({ onSelect }: { onSelect: (option: ActionMenuOption) => void }) => (
    <StatefulMenu
        items={items}
        rootRef={React.createRef()}
        className="action-menu-items"
        onItemSelect={({ item }: { item: ActionMenuItem }) => onSelect(item.id)}
        overrides={{
            List: { style: { position: 'fixed', bottom: '168px', right: '20px' } },
        }}
    />
);

interface Props {
    expanded: boolean;
    onToggle: () => void;
    onSelect: (option: ActionMenuOption) => void;
}

export const ActionMenu = ({ expanded, onToggle, onSelect }: Props) => (
    <>
        <ActionMenuButton toggle={onToggle} expanded={expanded} />
        {expanded ? <ActionMenuItems onSelect={onSelect} /> : ''}
    </>
);
