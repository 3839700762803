import React from 'react';
import { Block } from 'baseui/block';
import { StatefulSelect } from 'baseui/select';
import { FormControl } from 'baseui/form-control';
import { FacilitiesQuery } from './graphql/FacilitiesQuery';

interface Option {
    id: string;
    label: string;
}

interface Props {
    value: string;
    onChange: (value: string) => void;
}

export const FacilitySelect = ({ value: id, onChange }: Props) => (
    <Block display="flex" flexDirection="column" marginRight="scale200" style={{ flexGrow: 1 }}>
        <FormControl label="Facility">
            <FacilitiesQuery>
                {facilities => {
                    const options = facilities.map(({ name }) => ({ id: name, label: name }));
                    const initial = options.find(option => option.id === id);
                    const value: Option[] = initial ? [initial] : [];
                    return (
                        <StatefulSelect
                            options={options}
                            creatable
                            labelKey="label"
                            valueKey="id"
                            onChange={({ value }: { value: Option[] }) =>
                                onChange(value.length > 0 ? value[0].id : '')
                            }
                            initialState={{ value }}
                            clearable={false}
                            escapeClearsValue={false}
                            maxDropdownHeight="200px"
                            overrides={{
                                SelectArrow: { style: { display: 'none' } },
                            }}
                            placeholder={<span>e.g., LSB</span>}
                        />
                    );
                }}
            </FacilitiesQuery>
        </FormControl>
    </Block>
);
