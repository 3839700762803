import React, { Component } from 'react';
import { PageTitle } from '../PageTitle';
import { Button } from 'baseui/button';
import { RouteComponentProps, withRouter } from 'react-router';
import { Block } from 'baseui/block';
import { MeQuery } from '../graphql/MeQuery';

class _LogoutButton extends Component<RouteComponentProps<any>> {
    render() {
        return <Button onClick={this.logout}>Logout</Button>;
    }

    logout = () => {
        localStorage.removeItem('token');
        this.props.history.push(`/`);
    };
}

const LogoutButton = withRouter(_LogoutButton);

export const Account = () => (
    <MeQuery>
        {me => (
            <>
                <PageTitle title="Account" />
                <Block style={{ marginTop: '2em' }}>
                    <Block font="font400" style={{ marginBottom: '1em' }}>
                        You are logged in as <b>{me.name}</b>.
                    </Block>
                    <Block font="font400" style={{ marginBottom: '1em' }}>
                        You will receive email notifications at <b>{me.email}</b>.
                    </Block>
                    <LogoutButton />
                </Block>
            </>
        )}
    </MeQuery>
);
