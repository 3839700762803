import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './client/components/App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToTop } from './client/components/ScrollToTop';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { LightTheme, ThemeProvider } from 'baseui';
import { setContext } from 'apollo-link-context';

const engine = new Styletron();

const httpLink = createHttpLink({ uri: process.env.REACT_APP_SERVER_URL });
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } };
});
const client = new ApolloClient({ link: authLink.concat(httpLink), cache: new InMemoryCache() });

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <ApolloProvider client={client}>
                <ApolloHooksProvider client={client}>
                    <StyletronProvider value={engine}>
                        <ThemeProvider theme={LightTheme}>
                            <App />
                        </ThemeProvider>
                    </StyletronProvider>
                </ApolloHooksProvider>
            </ApolloProvider>
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
