import { User } from '../../types/HealthRecord';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';
import * as fragments from './fragments';

const MUTATION = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
            user {
                ...User
            }
        }
    }
    ${fragments.User}
`;

export interface Data {
    login: {
        token: string;
        user: User;
    };
}

interface Variables {
    email: string;
    password: string;
}

type UpdateFn = (proxy: any, result: { data?: Data }) => void;

export function useLoginMutation(variables: Variables, update: UpdateFn) {
    return useMutation<{}, Variables>(MUTATION, { variables, update });
}
