import dateFns from 'date-fns';

export function dateForISODate(dateStr?: string): Date | undefined {
    if (dateStr) {
        const year = parseInt(dateStr.slice(0, 4), 10);
        const month = parseInt(dateStr.slice(5, 7), 10) - 1;
        const day = parseInt(dateStr.slice(8, 10), 10);
        return new Date(year, month, day);
    } else {
        return undefined;
    }
}

export function isoDate(date: Date): string {
    return dateFns.format(date, 'YYYY-MM-DD');
}

export function isoDateAddDays(isoDate: string, days: number) {
    return dateFns.format(dateFns.addDays(dateForISODate(isoDate)!, days), 'YYYY-MM-DD');
}
