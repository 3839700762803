import { Component } from 'react';

interface ModalArgs {
    open: () => void;
    close: () => void;
    isOpen: boolean;
    toggle: (open: boolean) => void;
}

interface Props {
    isInitiallyOpen: boolean;
    children: (args: ModalArgs) => JSX.Element;
}

export class ModalStateContainer extends Component<Props> {
    static defaultProps = {
        isInitiallyOpen: false,
    };

    state = {
        isOpen: this.props.isInitiallyOpen,
    };

    toggle = (open = !this.state.isOpen) => {
        this.setState({
            isOpen: !!open,
        });
    };

    open = () => {
        this.toggle(true);
    };

    close = () => {
        this.toggle(false);
    };

    render() {
        return this.props.children({
            toggle: this.toggle,
            open: this.open,
            close: this.close,
            // setState: this.setState.bind(this),
            ...this.state,
        });
    }
}
