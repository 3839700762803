import React from 'react';
import { Button, KIND, SHAPE } from 'baseui/button';
import ChevronLeft from 'baseui/icon/chevron-left';

interface Props {
    onBack: () => void;
}

export const BackButton = ({ onBack }: Props) => (
    <Button
        kind={KIND.minimal}
        shape={SHAPE.square}
        onClick={onBack}
        style={{ marginRight: '1em', height: '36px' }}
    >
        <ChevronLeft size="1em" />
    </Button>
);
