import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UpdateTreatmentMutation } from '../graphql/UpdateTreatmentMutation';
import { HealthRecordProps } from '../../types/HealthRecord';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { PageTitle } from '../PageTitle';
import { withHealthRecord } from '../withHealthRecord';
import { compose } from 'react-apollo';
import { TextInput } from '../TextInput';
import { TreatmentFrequencySelect } from '../TreatmentFrequencySelect';
import { FormControl } from 'baseui/form-control';
import { Datepicker } from 'baseui/datepicker';
import { dateForISODate, isoDate } from '../../../shared/dateHelpers';
import { Checkbox } from 'baseui/checkbox';
import { EmailCompose } from '../EmailCompose';

function emailSubject({ healthRecord }: Props) {
    const location = [
        healthRecord.facility,
        `Rm ${healthRecord.room}`,
        healthRecord.cageLoc,
        healthRecord.numOfNum,
    ].join(' / ');
    return `Update for ${healthRecord.species} at ${location}`;
}

function emailMessage({ healthRecord }: Props) {
    const labName = healthRecord.lab ? healthRecord.lab.name : '';
    const salutation = `Dr. ${labName} and lab staff`;
    return `Hi ${salutation},\n\nPlease see the updated proposed treatment plan included here.`;
}

type Props = RouteComponentProps<any> & HealthRecordProps;

interface State {
    subjective: string;
    assessment: string;
    plan: string;
    treatmentFrequency: string;
    recheckDate: string;
    vetStaffNotes: string;
    requiresApproval: boolean;
    shouldSendNotification: boolean;
    subject: string;
    message: string;
}

export class UpdateTreatment extends Component<Props, State> {
    state: State = {
        subjective: this.props.healthRecord.subjective,
        assessment: this.props.healthRecord.assessment,
        plan: this.props.healthRecord.plan,
        treatmentFrequency: this.props.healthRecord.treatmentFrequency,
        recheckDate: this.props.healthRecord.recheckDate,
        vetStaffNotes: '',
        requiresApproval: false,
        shouldSendNotification: false,
        subject: emailSubject(this.props),
        message: emailMessage(this.props),
    };

    render() {
        const { healthRecord } = this.props;
        const id = parseInt(healthRecord.id as any);

        return (
            <>
                <PageTitle title="Update Treatment" onBack={() => this.props.history.goBack()} />
                <TextInput
                    label="Subjective/Objective"
                    value={this.state.subjective}
                    onChange={subjective => this.setState({ subjective })}
                    multiline={true}
                />
                <TextInput
                    label="Assessment"
                    value={this.state.assessment}
                    onChange={assessment => this.setState({ assessment })}
                    multiline={true}
                />
                <TextInput
                    label="Plan"
                    value={this.state.plan}
                    onChange={plan => this.setState({ plan })}
                    multiline={true}
                />
                <TreatmentFrequencySelect
                    value={this.state.treatmentFrequency}
                    onChange={value => this.setState({ treatmentFrequency: value })}
                />
                <FormControl label="Recheck On">
                    <Datepicker
                        value={dateForISODate(this.state.recheckDate)}
                        onChange={this.changeRecheckDate}
                        autoFocusCalendar={true}
                    />
                </FormControl>

                <Checkbox
                    checked={this.state.requiresApproval}
                    onChange={() =>
                        this.setState({ requiresApproval: !this.state.requiresApproval })
                    }
                >
                    Requires vet approval?
                </Checkbox>
                <Block style={{ marginTop: '1em' }}>
                    <Checkbox
                        checked={this.state.shouldSendNotification}
                        onChange={() =>
                            this.setState({
                                shouldSendNotification: !this.state.shouldSendNotification,
                            })
                        }
                    >
                        Notify via email?
                    </Checkbox>

                    {this.state.shouldSendNotification ? (
                        <EmailCompose
                            healthRecord={healthRecord}
                            subject={this.state.subject}
                            message={this.state.message}
                            onChange={(subject, message) => this.setState({ subject, message })}
                        />
                    ) : (
                        ''
                    )}
                </Block>

                <Block marginTop="1em">
                    <UpdateTreatmentMutation variables={{ id, fields: this.state }}>
                        {updateTreatment => (
                            <Button onClick={this.submit(updateTreatment)}>Submit</Button>
                        )}
                    </UpdateTreatmentMutation>
                </Block>
            </>
        );
    }

    goBack = () => {
        this.props.history.push(`/health-records/${this.props.healthRecord.id}`);
    };

    changeRecheckDate = ({ date }: { date: Date }) => {
        this.setState({ recheckDate: isoDate(date) });
    };

    submit = (mutate: () => void) => async () => {
        await mutate();
        this.props.history.push(`/health-records/${this.props.healthRecord.id}`);
    };
}

export default compose(
    withRouter,
    withHealthRecord,
)(UpdateTreatment);
