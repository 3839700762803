import React from 'react';
import { BrowseFacilities } from '../BrowseFacilities';
import { ListHealthRecords } from '../ListHealthRecords';
import { PageTitle } from '../PageTitle';
import { Head } from '../Head';
import { BrowseLabs } from '../BrowseLabs';

export const Browse = () => (
    <>
        <PageTitle title="Browse" />

        <Head text="Facilities" />
        <BrowseFacilities />

        <Head text="Labs" />
        <BrowseLabs />

        <Head text="Health Records" />
        <ListHealthRecords archived={false} />

        <Head text="Needs Approval" />
        <div>[TODO]</div>

        <Head text="Upcoming Rechecks" />
        <div>[TODO]</div>

        <Head text="Archived Health Records" />
        <ListHealthRecords archived={true} />
    </>
);
