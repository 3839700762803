import React from 'react';
import { Block } from 'baseui/block';
import { Link } from 'react-router-dom';
import { BackButton } from './BackButton';

interface Props {
    title: string;
    to?: string;
    onBack?: () => void;
}

export function PageTitle({ title, to, onBack }: Props) {
    if (onBack) {
        return (
            <Block display="flex" flexDirection="row" alignItems="center">
                <BackButton onBack={onBack} />
                <Block font="font600" style={{ fontWeight: 'normal' }}>
                    {title}
                </Block>
            </Block>
        );
    } else if (to) {
        return (
            <Block font="font600" style={{ fontWeight: 'normal' }}>
                <Link to={to} style={{ textDecoration: 'none', color: 'black' }}>
                    {title}
                </Link>
            </Block>
        );
    } else {
        return (
            <Block font="font600" style={{ fontWeight: 'normal' }}>
                {title}
            </Block>
        );
    }
}
