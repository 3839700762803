import React from 'react';
import gql from 'graphql-tag';
import { HealthRecordSummary } from '../../types/HealthRecord';
import { Loading, LoadingError } from '../Loading';
import { useQuery } from 'react-apollo-hooks';
import * as fragments from './fragments';

const QUERY = gql`
    query healthRecordsForAcuc($lab: String!, $acuc: String!) {
        healthRecordsForAcuc(lab: $lab, acuc: $acuc) {
            ...HealthRecordSummary
        }
    }
    ${fragments.HealthRecordSummary}
`;

interface Data {
    healthRecordsForAcuc: HealthRecordSummary[];
}

interface Variables {
    lab: string;
    acuc: string;
}

interface Props {
    lab: string;
    acuc: string;
    children: (summaries: HealthRecordSummary[]) => JSX.Element;
}

export function HealthRecordsForAcucQuery({ lab, acuc, children }: Props) {
    const { data, error, loading } = useQuery<Data, Variables>(QUERY, { variables: { lab, acuc } });
    if (loading) return <Loading />;
    if (error) return <LoadingError error={error} />;
    return children(data!.healthRecordsForAcuc);
}
