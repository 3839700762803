import React, { ChangeEvent } from 'react';
import { Input } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import { Block } from 'baseui/block';
import { Textarea } from 'baseui/textarea';

const MultilineTextInput = ({ value, onChange, placeholder, disabled, lines }: Props) => (
    <Textarea
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        placeholder={placeholder || ''}
        disabled={disabled}
        rows={lines || 3}
    />
);

const SingleTextInput = ({ onChange, placeholder, value, isPassword, disabled }: Props) => (
    <Input
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        placeholder={placeholder || ''}
        value={value}
        type={isPassword ? 'password' : 'text'}
        disabled={disabled}
    />
);

interface Props {
    label: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    multiline?: boolean;
    isPassword?: boolean;
    disabled?: boolean;
    lines?: number;
}

export function TextInput(props: Props) {
    const { label, multiline } = props;
    return (
        <Block display="flex" flexDirection="column" marginRight="scale200">
            <FormControl label={label}>
                {multiline ? <MultilineTextInput {...props} /> : <SingleTextInput {...props} />}
            </FormControl>
        </Block>
    );
}
