import React from 'react';
import { HealthRecordProps, HealthRecord } from '../types/HealthRecord';
import { Treatment, Updates } from './pages/HealthRecordView';
import { Block } from 'baseui/block';
import { AnimalHealthRecordDetail } from './AnimalHealthRecordDetail';
import { AnimalHealthRecordHeader } from './AnimalHealthRecordHeader';
import { Notification, KIND } from 'baseui/notification';

interface Props {
    healthRecord: HealthRecord;
}

const ArchivedNotice = ({ healthRecord }: Props) => {
    if (healthRecord.archivedAt)
        return (
            <Block display="flex" flexDirection="column">
                <Notification
                    kind={KIND.warning}
                    overrides={{
                        Body: { style: { width: 'auto', fontWeight: 'normal', marginTop: '1em' } },
                    }}
                >
                    This animal has been archived.
                </Notification>
            </Block>
        );
    else return <></>;
};

export const AnimalHealthRecord = ({ healthRecord }: HealthRecordProps) => (
    <>
        <ArchivedNotice healthRecord={healthRecord} />
        <Block style={{ marginTop: '1em' }}>
            <AnimalHealthRecordHeader healthRecord={healthRecord} allowEdits={true} />
            <AnimalHealthRecordDetail healthRecord={healthRecord} allowEdits={true} />
            <Treatment healthRecord={healthRecord} />
            <Updates healthRecord={healthRecord} />
        </Block>
    </>
);
