import React from 'react';
import { Link } from 'react-router-dom';
import { RoomsQuery } from '../graphql/RoomsQuery';
import { PageTitle } from '../PageTitle';
import { Head } from '../Head';
import { Block } from 'baseui/block';
import ChevronRight from 'baseui/icon/chevron-right';
import { Breadcrumbs } from 'baseui/breadcrumbs';

interface Facility {
    name: string;
}

export interface Room {
    name: string;
}

const RoomLink = ({ facility, room }: { facility: Facility; room: Room }) => (
    <Link
        to={`/browse/facilities/${facility.name}/rooms/${room.name}`}
        className="health-record-list-item"
    >
        <Block
            font="font400"
            display="flex"
            justifyContent="space-between"
            style={{ borderTop: '1px solid #ccc', padding: '0.25em 0' }}
            alignItems="center"
        >
            <Block>Rm {room.name}</Block>
            <ChevronRight size="2em" />
        </Block>
    </Link>
);

interface Props {
    facility: string;
}

export const BrowseFacility = ({ facility }: Props) => (
    <>
        <PageTitle title="Browse" to="/browse" />
        <Breadcrumbs>
            <span>{facility}</span>
        </Breadcrumbs>

        <Head text="Rooms" />
        <Block style={{ borderBottom: '1px solid #ccc' }}>
            <RoomsQuery facility={facility}>
                {rooms => (
                    <>
                        {rooms.length === 0 ? <Block font="font400">None</Block> : ''}
                        {rooms.map((room, key) => (
                            <RoomLink key={key} facility={{ name: facility }} room={room} />
                        ))}
                    </>
                )}
            </RoomsQuery>
        </Block>
    </>
);
