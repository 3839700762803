import React from 'react';
import { HealthRecord } from '../types/HealthRecord';
import { Block } from 'baseui/block';
import { ModalStateContainer } from './ModalStateContainer';
import { EditLocation } from './EditLocation';
import { FieldHead } from './FieldHead';
import { AnimalLocationHeader } from './AnimalLocationHeader';

interface Props {
    healthRecord: HealthRecord;
    allowEdits: boolean;
}

export function AnimalHealthRecordHeader({ healthRecord, allowEdits }: Props) {
    if (allowEdits) {
        return (
            <ModalStateContainer>
                {({ open, close, isOpen }) => (
                    <>
                        <Block display="flex" flexDirection="column">
                            <FieldHead text="Location" />
                            <AnimalLocationHeader healthRecord={healthRecord} openModal={open} />
                        </Block>
                        <EditLocation healthRecord={healthRecord} onClose={close} isOpen={isOpen} />
                    </>
                )}
            </ModalStateContainer>
        );
    } else {
        return (
            <Block display="flex" flexDirection="column">
                <FieldHead text="Location" />
                <AnimalLocationHeader healthRecord={healthRecord} />
            </Block>
        );
    }
}
