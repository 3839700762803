import React from 'react';
import { Block } from 'baseui/block';

export const SubHead = ({ text }: { text: string }) => (
    <Block font="font400" style={{ marginTop: '1.5em', marginBottom: '0.5em' }}>
        {text}
    </Block>
);

export const Head = ({ text }: { text: string }) => (
    <Block font="font500" style={{ marginTop: '2em', marginBottom: '0.5em' }}>
        {text}
    </Block>
);
