import React from 'react';
import { Block } from 'baseui/block';
import { withRouter, RouteComponentProps } from 'react-router';
import { Calendar, List, Search, User } from 'react-feather';

interface Props {
    active: boolean;
    onClick: () => void;
    children?: React.ReactNode;
}

const MenuItem = ({ active, onClick, children }: Props) => (
    <Block
        onClick={onClick}
        font="font200"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="6em"
        color={active ? 'blue' : 'black'}
    >
        {children}
    </Block>
);

function isSchedule(path: string) {
    return ['/', '/schedule'].includes(path);
}

export function Footer({ history }: RouteComponentProps<any>) {
    const path = location.pathname;
    const onNavigate = (to: string) => () => {
        history.push(to);
    };

    return (
        <div
            style={{
                zIndex: 1000,
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'white',
                padding: '1em',
                borderTop: '1px solid #ddd',
            }}
        >
            <Block display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                <MenuItem active={isSchedule(path)} onClick={onNavigate('/')}>
                    <Calendar size="2em" /> Schedule
                </MenuItem>
                <MenuItem active={path === '/browse'} onClick={onNavigate('/browse')}>
                    <List size="2em" /> Browse
                </MenuItem>
                <MenuItem active={path === '/search'} onClick={onNavigate('/search')}>
                    <Search size="2em" /> Search
                </MenuItem>
                <MenuItem active={path === '/account'} onClick={onNavigate('/account')}>
                    <User size="2em" /> Account
                </MenuItem>
            </Block>
        </div>
    );
}

export default withRouter(Footer);
