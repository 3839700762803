import React from 'react';
import { HealthRecord } from '../types/HealthRecord';
import { Field } from './Field';
import * as dateFns from 'date-fns';
import { dateForISODate } from '../../shared/dateHelpers';
import { Block } from 'baseui/block';

function formatDate(isoDate?: string) {
    const date = dateForISODate(isoDate);
    if (date) {
        return dateFns.format(date, 'MMMM D, YYYY');
    } else {
        return '';
    }
}

interface Props {
    healthRecord: HealthRecord;
    showDetails?: boolean;
}

export const TreatmentSummary = ({ healthRecord, showDetails = false }: Props) => (
    <div className="treatment" style={{ margin: '1em 0' }}>
        <Field
            label="Subjective/Objective"
            value={healthRecord.subjective || 'N/A'}
            important={true}
        />
        <Field label="Assessment" value={healthRecord.assessment || 'N/A'} important={true} />
        <Field label="Plan" value={healthRecord.plan || 'N/A'} important={true} />
        {showDetails ? (
            <>
                <Block display="flex" flexDirection="row">
                    <Field label="Tx Frequency" value={healthRecord.treatmentFrequency} />
                    <Field label="Recheck On" value={formatDate(healthRecord.recheckDate)} />
                </Block>
                <Field label="Notes" value={healthRecord.vetStaffNotes} important={true} />
                <Field label="Reported On" value={formatDate(healthRecord.reportedDate)} />
            </>
        ) : (
            ''
        )}
    </div>
);
