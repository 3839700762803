import { Mutation, MutationFn } from 'react-apollo';
import gql from 'graphql-tag';
import { UpdateLabInput } from '../../../shared/HealthRecord';

export const MUTATION = gql`
    mutation UpdateLab($id: Int!, $fields: UpdateLabInput!) {
        updateLab(id: $id, fields: $fields) {
            id
            lab {
                name
            }
            acuc
            cageId
        }
    }
`;

export interface Data {}

export interface Variables {
    id: number;
    fields: UpdateLabInput;
}

export class UpdateLabMutation extends Mutation<Data, Variables> {}

export type UpdateLabMutationFn = MutationFn<Data, Variables>;
