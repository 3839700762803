import gql from 'graphql-tag';

export const LabStaff = gql`
    fragment LabStaff on LabStaff {
        id
        lab
        name
        title
        email
        labStaffAcucs {
            acuc
            receiveNotifications
        }
    }
`;
