import React from 'react';
import { Block } from 'baseui/block';
import { FieldHead } from './FieldHead';

interface FieldProps {
    label: string;
    value: string;
    important?: boolean;
}

export const Field = ({ label, value, important = false }: FieldProps) => (
    <Block display="flex" flexDirection="column" className="field">
        <FieldHead text={label} />
        <Block font={important ? 'font500' : 'font450'} style={{ fontWeight: 'normal' }}>
            {value || 'N/A'}
        </Block>
    </Block>
);
