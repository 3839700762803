import React from 'react';
import gql from 'graphql-tag';
import { UpdateTreatmentInput } from '../../../shared/UpdateTreatment';
import { useMutation } from 'react-apollo-hooks';
import * as fragments from './fragments';

const MUTATION = gql`
    mutation UpdateTreatment($id: Int!, $fields: UpdateTreatmentInput!) {
        updateTreatment(id: $id, fields: $fields) {
            ...FullHealthRecord
        }
    }
    ${fragments.FullHealthRecord}
`;

interface Data {}

interface Variables {
    id: number;
    fields: UpdateTreatmentInput;
}

export function useUpdateTreatmentMutation(variables: Variables) {
    return useMutation<Data, Variables>(MUTATION, { variables });
}

interface Props {
    variables: Variables;
    children: (act: any) => JSX.Element;
}

export function UpdateTreatmentMutation({ variables, children }: Props) {
    const updateTreatment = useUpdateTreatmentMutation(variables);
    return children(updateTreatment);
}
