import React from 'react';
import { HealthRecordsQuery } from '../graphql/HealthRecordsForRoomQuery';
import { HealthRecordList } from '../HealthRecordList';
import { PageTitle } from '../PageTitle';
import { Head } from '../Head';
import { Breadcrumbs } from 'baseui/breadcrumbs';
import { Link } from 'react-router-dom';

interface Props {
    facility: string;
    room: string;
}

export const BrowseRoom = ({ facility, room }: Props) => (
    <>
        <PageTitle title="Browse" to="/browse" />
        <Breadcrumbs>
            <Link to={`/browse/facilities/${facility}`} className="breadcrumbs-link">
                {facility}
            </Link>
            <span>Rm {room}</span>
        </Breadcrumbs>

        <Head text="Animals" />
        <HealthRecordsQuery facility={facility} room={room}>
            {summaries => <HealthRecordList healthRecords={summaries} />}
        </HealthRecordsQuery>
    </>
);
