import gql from 'graphql-tag';
import { ReviewerRole } from '../../../shared/ReviewTreatment';
import { useMutation } from 'react-apollo-hooks';
import * as fragments from './fragments';

export const MUTATION = gql`
    mutation ReviewTreatment(
        $id: Int!
        $reviewerRole: String!
        $approve: Boolean!
        $notes: String!
    ) {
        reviewTreatment(id: $id, reviewerRole: $reviewerRole, approve: $approve, notes: $notes) {
            ...FullHealthRecord
        }
    }
    ${fragments.FullHealthRecord}
`;

interface Variables {
    id: number;
    reviewerRole: ReviewerRole;
    approve: boolean;
    notes: string;
}

interface Props {
    variables: Variables;
    children: (act: () => void) => JSX.Element;
}

export function ReviewTreatmentMutation({ variables, children }: Props) {
    return children(useMutation<{}, Variables>(MUTATION, { variables }));
}

export function useReviewTreatmentMutation(variables: Variables) {
    return useMutation<{}, Variables>(MUTATION, { variables });
}
